import CampaignData from '../../components/CampaignData';

const CampaignListProgress = () => {
    return (
        <div className="dashboard-content-block">
            <div className="dashbaord-heading">
            Campaigns In Progress
            </div>
            <div className="user-table-block">
                <CampaignData viewType="admin" dataType="in-progress"/>
            </div>
        </div>
    );
};

export default CampaignListProgress;