import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Table, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Context } from "../../../components/Context";
import { CAMPAIGN_DEVICES, fetchData } from "../../../components/Service";
import { ActionButton, displayError, DownloadButton, uploadFileToAWSBucket } from "../../../components/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const Devices = (props) => {
    const navigate = useNavigate()
    // State to track the selected radio button
    const [selectedOption, setSelectedOption] = useState("Both");

    // Handler for radio button change
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const languages = [
        { value: "aa", label: "Afar" },
        { value: "ab", label: "Abkhazian" },
        { value: "ae", label: "Avestan" },
        { value: "af", label: "Afrikaans" },
        { value: "ak", label: "Akan" },
        { value: "am", label: "Amharic" },
        { value: "an", label: "Aragonese" },
        { value: "ar", label: "Arabic" },
        { value: "as", label: "Assamese" },
        { value: "av", label: "Avaric" },
        { value: "ay", label: "Aymara" },
        { value: "az", label: "Azerbaijani" },
        { value: "ba", label: "Bashkir" },
        { value: "be", label: "Belarusian" },
        { value: "bg", label: "Bulgarian" },
        { value: "bh", label: "Bihari languages" },
        { value: "bi", label: "Bislama" },
        { value: "bm", label: "Bambara" },
        { value: "bn", label: "Bengali" },
        { value: "bo", label: "Tibetan" },
        { value: "br", label: "Breton" },
        { value: "bs", label: "Bosnian" },
        { value: "ca", label: "Catalan; Valencian" },
        { value: "ce", label: "Chechen" },
        { value: "ch", label: "Chamorro" },
        { value: "co", label: "Corsican" },
        { value: "cr", label: "Cree" },
        { value: "cs", label: "Czech" },
        {
            value: "cu",
            label: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
        },
        { value: "cv", label: "Chuvash" },
        { value: "cy", label: "Welsh" },
        { value: "da", label: "Danish" },
        { value: "de", label: "German" },
        { value: "dv", label: "Divehi; Dhivehi; Maldivian" },
        { value: "dz", label: "Dzongkha" },
        { value: "ee", label: "Ewe" },
        { value: "el", label: "Greek, Modern (1453-)" },
        { value: "en", label: "English" },
        { value: "eo", label: "Esperanto" },
        { value: "es", label: "Spanish; Castilian" },
        { value: "et", label: "Estonian" },
        { value: "eu", label: "Basque" },
        { value: "fa", label: "Persian" },
        { value: "ff", label: "Fulah" },
        { value: "fi", label: "Finnish" },
        { value: "fj", label: "Fijian" },
        { value: "fo", label: "Faroese" },
        { value: "fr", label: "French" },
        { value: "fy", label: "Western Frisian" },
        { value: "ga", label: "Irish" },
        { value: "gd", label: "Gaelic; Scomttish Gaelic" },
        { value: "gl", label: "Galician" },
        { value: "gn", label: "Guarani" },
        { value: "gu", label: "Gujarati" },
        { value: "gv", label: "Manx" },
        { value: "ha", label: "Hausa" },
        { value: "he", label: "Hebrew" },
        { value: "hi", label: "Hindi" },
        { value: "ho", label: "Hiri Motu" },
        { value: "hr", label: "Croatian" },
        { value: "ht", label: "Haitian; Haitian Creole" },
        { value: "hu", label: "Hungarian" },
        { value: "hy", label: "Armenian" },
        { value: "hz", label: "Herero" },
        {
            value: "ia",
            label: "Interlingua (International Auxiliary Language Association)",
        },
        { value: "id", label: "Indonesian" },
        { value: "ie", label: "Interlingue; Occidental" },
        { value: "ig", label: "Igbo" },
        { value: "ii", label: "Sichuan Yi; Nuosu" },
        { value: "ik", label: "Inupiaq" },
        { value: "io", label: "Ido" },
        { value: "is", label: "Icelandic" },
        { value: "it", label: "Italian" },
        { value: "iu", label: "Inuktitut" },
        { value: "ja", label: "Japanese" },
        { value: "jv", label: "Javanese" },
        { value: "ka", label: "Georgian" },
        { value: "kg", label: "Kongo" },
        { value: "ki", label: "Kikuyu; Gikuyu" },
        { value: "kj", label: "Kuanyama; Kwanyama" },
        { value: "kk", label: "Kazakh" },
        { value: "kl", label: "Kalaallisut; Greenlandic" },
        { value: "km", label: "Central Khmer" },
        { value: "kn", label: "Kannada" },
        { value: "ko", label: "Korean" },
        { value: "kr", label: "Kanuri" },
        { value: "ks", label: "Kashmiri" },
        { value: "ku", label: "Kurdish" },
        { value: "kv", label: "Komi" },
        { value: "kw", label: "Cornish" },
        { value: "ky", label: "Kirghiz; Kyrgyz" },
        { value: "la", label: "Latin" },
        { value: "lb", label: "Luxembourgish; Letzeburgesch" },
        { value: "lg", label: "Ganda" },
        { value: "li", label: "Limburgan; Limburger; Limburgish" },
        { value: "ln", label: "Lingala" },
        { value: "lo", label: "Lao" },
        { value: "lt", label: "Lithuanian" },
        { value: "lu", label: "Luba-Katanga" },
        { value: "lv", label: "Latvian" },
        { value: "mg", label: "Malagasy" },
        { value: "mh", label: "Marshallese" },
        { value: "mi", label: "Maori" },
        { value: "mk", label: "Macedonian" },
        { value: "ml", label: "Malayalam" },
        { value: "mn", label: "Mongolian" },
        { value: "mr", label: "Marathi" },
        { value: "ms", label: "Malay" },
        { value: "mt", label: "Maltese" },
        { value: "my", label: "Burmese" },
        { value: "na", label: "Nauru" },
        {
            value: "nb",
            label: "Bokmål, Norwegian; Norwegian Bokmål",
        },
        { value: "nd", label: "Ndebele, North; North Ndebele" },
        { value: "ne", label: "Nepali" },
        { value: "ng", label: "Ndonga" },
        { value: "nl", label: "Dutch; Flemish" },
        { value: "nn", label: "Norwegian Nynorsk; Nynorsk, Norwegian" },
        { value: "no", label: "Norwegian" },
        { value: "nr", label: "Ndebele, South; South Ndebele" },
        { value: "nv", label: "Navajo; Navaho" },
        { value: "ny", label: "Chichewa; Chewa; Nyanja" },
        { value: "oc", label: "Occitan (post 1500)" },
        { value: "oj", label: "Ojibwa" },
        { value: "om", label: "Oromo" },
        { value: "or", label: "Oriya" },
        { value: "os", label: "Ossetian; Ossetic" },
        { value: "pa", label: "Panjabi; Punjabi" },
        { value: "pi", label: "Pali" },
        { value: "pl", label: "Polish" },
        { value: "ps", label: "Pushto; Pashto" },
        { value: "pt", label: "Portuguese" },
        { value: "qu", label: "Quechua" },
        { value: "rm", label: "Romansh" },
        { value: "rn", label: "Rundi" },
        { value: "ro", label: "Romanian; Moldavian; Moldovan" },
        { value: "ru", label: "Russian" },
        { value: "rw", label: "Kinyarwanda" },
        { value: "sa", label: "Sanskrit" },
        { value: "sc", label: "Sardinian" },
        { value: "sd", label: "Sindhi" },
        { value: "se", label: "Northern Sami" },
        { value: "sg", label: "Sango" },
        { value: "si", label: "Sinhala; Sinhalese" },
        { value: "sk", label: "Slovak" },
        { value: "sl", label: "Slovenian" },
        { value: "sm", label: "Samoan" },
        { value: "sn", label: "Shona" },
        { value: "so", label: "Somali" },
        { value: "sq", label: "Albanian" },
        { value: "sr", label: "Serbian" },
        { value: "ss", label: "Swati" },
        { value: "st", label: "Sotho, Southern" },
        { value: "su", label: "Sundanese" },
        { value: "sv", label: "Swedish" },
        { value: "sw", label: "Swahili" },
        { value: "ta", label: "Tamil" },
        { value: "te", label: "Telugu" },
        { value: "tg", label: "Tajik" },
        { value: "th", label: "Thai" },
        { value: "ti", label: "Tigrinya" },
        { value: "tk", label: "Turkmen" },
        { value: "tl", label: "Tagalog" },
        { value: "tn", label: "Tswana" },
        { value: "to", label: "Tonga (Tonga Islands)" },
        { value: "tr", label: "Turkish" },
        { value: "ts", label: "Tsonga" },
        { value: "tt", label: "Tatar" },
        { value: "tw", label: "Twi" },
        { value: "ty", label: "Tahitian" },
        { value: "ug", label: "Uighur; Uyghur" },
        { value: "uk", label: "Ukrainian" },
        { value: "ur", label: "Urdu" },
        { value: "uz", label: "Uzbek" },
        { value: "ve", label: "Venda" },
        { value: "vi", label: "Vietnamese" },
        { value: "vo", label: "Volapük" },
        { value: "wa", label: "Walloon" },
        { value: "wo", label: "Wolof" },
        { value: "xh", label: "Xhosa" },
        { value: "yi", label: "Yiddish" },
        { value: "yo", label: "Yoruba" },
        { value: "za", label: "Zhuang; Chuang" },
        { value: "zh", label: "Chinese" },
        { value: "zu", label: "Zulu" },
    ];

    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const [deviceIdsURL, setDeviceIdsURL] = useState(false)
    const [selectedLanguages, setSelectedLanguages] = useState();
    const [mobileAllChecked, setMobileAllChecked] = useState(false);
    const [mobileCheckboxes, setMobileCheckboxes] = useState({
        spios: false,
        spand: false,
        spwnd: false,
        mios: false,
        mand: false,
        mwnd: false
    });

    const handleMobileAllChange = (event) => {
        const checked = event.target.checked;
        setMobileAllChecked(checked);
        const updatedChildCheckboxes = Object.keys(mobileCheckboxes).reduce((acc, key) => {
        acc[key] = checked;
        return acc;
        }, {});
        setMobileCheckboxes(updatedChildCheckboxes);
    };

    const handleMobileChange = (event) => {
        const { name, checked } = event.target;
        const updatedChildCheckboxes = {
        ...mobileCheckboxes,
        [name]: checked
        };
        setMobileCheckboxes(updatedChildCheckboxes);
        const allChecked = Object.values(updatedChildCheckboxes).every(value => value);

        setMobileAllChecked(allChecked);
    };

    const [desktopAllChecked, setDesktopAllChecked] = useState(false);
    const [desktopCheckboxes, setDesktopCheckboxes] = useState({
        mos: false,
        wnd: false,
        lix: false
    });

    const handleDesktopAllChange = (event) => {
        const checked = event.target.checked;
        setDesktopAllChecked(checked);
        const updatedChildCheckboxes = Object.keys(desktopCheckboxes).reduce((acc, key) => {
        acc[key] = checked;
        return acc;
        }, {});
        setDesktopCheckboxes(updatedChildCheckboxes);
    };

    const handleDesktopChange = (event) => {
        const { name, checked } = event.target;
        const updatedChildCheckboxes = {
        ...desktopCheckboxes,
        [name]: checked
        };
        setDesktopCheckboxes(updatedChildCheckboxes);
        const allChecked = Object.values(updatedChildCheckboxes).every(value => value);

        setDesktopAllChecked(allChecked);
    };

    const saveData = (e) => {
        let formData = new FormData(document.getElementById("campaign-device"));

        let data = {
            userId: context && context.id ? context.id : 0,
            campaignId: context && context.campaignId ? context.campaignId : "",
            deviceMobile: JSON.stringify(mobileCheckboxes),
            deviceDesktop: JSON.stringify(desktopCheckboxes),
            deviceLanguage: JSON.stringify(selectedLanguages),
            cappingFrequency: formData.get("cappingFrequency"),
            cappingPeriod: formData.get("cappingPeriod"),
            trafficType: selectedOption,
            deviceIdsURL: deviceIdsURL
        };

        setTinyloader(true);

        fetchData(CAMPAIGN_DEVICES, "POST", data, true, false, (res) => {
            setTinyloader(false);
            displayError(res.errors);
            if (res.status === "success") {
                setContext(prevValue => ({
                    ...prevValue,
                    campaignId: res?.records?.id,
                    tabName: res?.records?.formNamePath
                }))
                localStorage.setItem('__t6t_campaign_id', res?.records?.id)
                props.makeTabAcive("", res?.records?.id)
            }
        });
    };

    const uploadDeviceIds = async (e) => {
        setFileUploadLoader(true)
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        setDeviceIdsURL(file)
    }

    useEffect(() => {
        if(props && props.campaignData) {
            setMobileCheckboxes(props.campaignData.deviceMobile ? JSON.parse(props.campaignData.deviceMobile) : {
                spios: false,
                spand: false,
                spwnd: false,
                mios: false,
                mand: false,
                mwnd: false
            });

            setMobileAllChecked(Object.values(props.campaignData.deviceMobile ? JSON.parse(props.campaignData.deviceMobile) : {
                spios: false,
                spand: false,
                spwnd: false,
                mios: false,
                mand: false,
                mwnd: false
            }).every(value => value));

            setDesktopCheckboxes(props.campaignData.deviceDesktop ? JSON.parse(props.campaignData.deviceDesktop) : {
                mos: false,
                wnd: false,
                lix: false
            });

            setDesktopAllChecked(Object.values(props.campaignData.deviceDesktop ? JSON.parse(props.campaignData.deviceDesktop) : {
                mos: false,
                wnd: false,
                lix: false
            }).every(value => value));

            setSelectedOption(props.campaignData.trafficType ? props.campaignData.trafficType : "Both");
            setSelectedLanguages(props.campaignData.deviceLanguage ? JSON.parse(props.campaignData.deviceLanguage) : "")
            setDeviceIdsURL(props.campaignData.deviceIdsURL ? props.campaignData.deviceIdsURL : "")
        }
    }, [props])

    return (
        <>
            <div className="tab-inner-content">
                <Form id="campaign-device">
                    <h4 className="form-heading">Devices</h4>
                    <div className="os-devices-table-block">
                        <p>OS and Device Type</p>
                        <Table className="custom-device-table" responsive>
                        <tr className="oshead">
                            <th>
                            <label className="checkbox-block normal-checkbox-block">
                                <p>Select all</p>
                                <input type="checkbox" checked={mobileAllChecked} onChange={handleMobileAllChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </th>
                            <th className="text-center">iOS</th>
                            <th className="text-center">Android</th>
                            <th className="text-center">Windows</th>
                        </tr>
                        <tr>
                            <th>Smartphone</th>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="spios" checked={mobileCheckboxes.spios} onChange={handleMobileChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </td>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="spand" checked={mobileCheckboxes.spand} onChange={handleMobileChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </td>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="spwnd" checked={mobileCheckboxes.spwnd} onChange={handleMobileChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </td>
                        </tr>
                        <tr>
                            <th>Tablet</th>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="mios" checked={mobileCheckboxes.mios} onChange={handleMobileChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </td>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="mand" checked={mobileCheckboxes.mand} onChange={handleMobileChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </td>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="mwnd" checked={mobileCheckboxes.mwnd} onChange={handleMobileChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </td>
                        </tr>
                        <tr className="oshead">
                            <th>
                            <label className="checkbox-block normal-checkbox-block">
                                <p>Select all</p>
                                <input type="checkbox" checked={desktopAllChecked} onChange={handleDesktopAllChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </th>
                            <th className="text-center">MacOS</th>
                            <th className="text-center">Linux</th>
                            <th className="text-center">Windows</th>
                        </tr>
                        <tr>
                            <th>Desktop</th>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="mos" checked={desktopCheckboxes.mos} onChange={handleDesktopChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </td>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="lix" checked={desktopCheckboxes.lix} onChange={handleDesktopChange} />
                                <span className="checkmark"></span>
                            </label>
                            </td>
                            <td className="text-center">
                            <label className="checkbox-block normal-checkbox-block">
                                <input type="checkbox" name="wnd" checked={desktopCheckboxes.wnd} onChange={handleDesktopChange}/>
                                <span className="checkmark"></span>
                            </label>
                            </td>
                        </tr>
                        </Table>
                    </div>
                    <h4 className="form-heading">Language Targeting</h4>
                    <Form.Group className="form-group">
                        <Form.Label>Languages</Form.Label>
                        <Select 
                            closeMenuOnSelect={false}
                            isMulti
                            options={languages} 
                            placeholder="All languages included"
                            name="deviceLanguage"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={selectedLanguages}
                            onChange={(value) => setSelectedLanguages(value)}
                        />
                    </Form.Group>
                    <h4 className="form-heading">Frequency Capping</h4>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="form-group">
                                <Form.Label>Frequency</Form.Label>
                                <Form.Control type="number" name="cappingFrequency" placeholder="Max. number of impacts" defaultValue={props?.campaignData?.cappingFrequency}/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="form-group">
                                <Form.Label>Period</Form.Label>
                                <Form.Select name="cappingPeriod">
                                    <option value="Hour" selected={props?.campaignData?.cappingPeriod === "Hour"}>Hour</option>
                                    <option value="Day" selected={props?.campaignData?.cappingPeriod === "Day"}>Day</option>
                                    <option value="Week" selected={props?.campaignData?.cappingPeriod === "Week"}>Week</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h4 className="form-heading">Traffic Limits</h4>
                    <div className="traffic-limits-section">
                        <p>Traffic Type</p>
                        <Row>
                        <Col sm={3}>
                            <label className={selectedOption === 'Both' ? 'radio-block custom-radio-button active' : 'radio-block custom-radio-button'}>Both
                            <input 
                                type="radio"
                                name="trafficType"
                                value="Both"
                                checked={selectedOption === 'Both'}
                                onChange={handleRadioChange}
                            />
                            <span className="checkmark"></span>
                            </label>
                        </Col>
                        <Col sm={3}>
                            <label className={selectedOption === 'In-App' ? 'radio-block custom-radio-button active' : 'radio-block custom-radio-button'}>In-App
                            <input
                                type="radio"
                                name="trafficType"
                                value="In-App"
                                checked={selectedOption === 'In-App'}
                                onChange={handleRadioChange}
                            />
                            <span className="checkmark"></span>
                            </label>
                        </Col>
                        <Col sm={3}>
                            <label className={selectedOption === 'Mobile-Web' ? 'radio-block custom-radio-button active' : 'radio-block custom-radio-button'}>Mobile-Web
                            <input
                                type="radio"
                                name="trafficType"
                                value="Mobile-Web"
                                checked={selectedOption === 'Mobile-Web'}
                                onChange={handleRadioChange}
                            />
                            <span className="checkmark"></span>
                            </label>
                        </Col>
                        </Row>
                        <div className="form-group">
                        <label className="form-label">Upload and serve on a selected list of device-id’s</label>
                        <div className="double-field-block">
                            <div className="file-upload-block">
                            <Form.Control type="file" onChange={(e) => uploadDeviceIds(e)}/>
                            <Button variant="light" className="file-upload-btn"> { fileUploadLoader ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload File</Button>
                            </div>
                            <span className="ps-2">{deviceIdsURL}</span> { props && props.campaignData && props.campaignData.deviceIdsURL && <DownloadButton url={props.campaignData.deviceIdsURL} /> }
                        </div>
                        </div>
                    </div>
                    <ActionButton saveData={saveData} tinyloader={tinyloader} />
                </Form>
            </div>
        </>
    );
};

export default Devices;
