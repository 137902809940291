import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Context } from "./Context";
import Login from "../modules/login/login";
import { AUTH_USER_DETAIL, fetchData } from "./Service";
import Loading from "../modules/pages/Loading";
import Base from "./layout/Base";
import Frontend from "./layout/Frontend";
import Backend from "./layout/Backend";
import Dashboard from "../modules/admin-dashboard/dashboard";
import Campaign from "../modules/campaign/campaign";
import SubmitedCampaign from "../modules/submited-campaign/submited-campaign";
import CampaignList from "../modules/admin-dashboard/campaign-list";
import CampaignListProgress from "../modules/admin-dashboard/campaign-list-progress";
import Setting from "../modules/admin-dashboard/setting";
import ChangePassword from "../modules/admin-dashboard/change-password";
import Users from "../modules/admin-dashboard/users";
import DSPCredentials from "../modules/admin-dashboard/DSPCredentials";
import Reporting from "../modules/reporting/Reporting";
import AWSS3Bucket from "../modules/aws-s3-bucket/aws-s3-bucket"
import AwsS3Bucket from "../modules/aws-s3-bucket/aws-s3-bucket";

const Routing = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [context, setContext] = useState()
    const [, setLoader] = useState(false)
    const[token] = useState(localStorage.getItem('__t6ttoken'))

    useEffect(() => {
        if(token && !context){
            setLoader(false);
            fetchData(AUTH_USER_DETAIL, 'POST', {}, true, false, (res) => {
                setLoader(false);
                if (res.status === "success") {
                    setContext({...res.records, campaignId: localStorage.getItem('__t6t_campaign_id')})
    
                    if (res.records.role === 'user') {
                        //if(!["/saved-campaigns", "/get-started"].includes(location.pathname)) {
                          //  navigate(`/${res.records.campaign.path}`);
                        //}
                    } else {
                        if(["/login"].includes(location.pathname)) {
                            navigate(`/admin/dashboard`);
                        }
                    }
                } else {
                    navigate('/');
                }
            });
        }
    }, [])

    return (
        <Context.Provider value={[ context, setContext ]}>
            <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/" element={<Base/>}>
                    <Route index element={<Login />} />
                    <Route path="login" element={<Login />} />
                </Route>
                { context && context.id ?
                <>
                    <Route path="/campaign/" element={<Frontend/>}>
                        <Route path="" element={<Campaign />} />
                        <Route path="submited" element={<SubmitedCampaign />} />
                    </Route>
                    { context.role === "admin" && 
                    <Route path="/admin/" element={<Backend/>}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="users" element={<Users />} />
                        <Route path="submitted-campaign" element={<CampaignList />} />
                        <Route path="campaign-in-progress" element={<CampaignListProgress />} />
                        <Route path="setting" element={<Setting />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="dsp-credentials" element={<DSPCredentials />} />
                        <Route path="dsp-reporting" element={<Reporting />} />
                    </Route> 
                    }
                </>
                :
                    <Route path="/*" element={<Loading />} />
                }
                <Route path="aws-s3-bucket" element={<AwsS3Bucket />} />
            </Routes>
        </Context.Provider>
    );
};

export default Routing;
