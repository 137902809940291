import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink, useLocation } from "react-router-dom";

const Aside = () => {
    const location = useLocation();

    return (
        <div className="dashboard-sidebar">
            <ul>
                <li><NavLink to="/admin/dashboard" className={location.pathname === "/admin/dashboard" ? "active" : ""}><FontAwesomeIcon icon="fa-solid fa-shop" /> Dashboard</NavLink></li>
                <li><NavLink to="/admin/users" className={location.pathname === "/admin/users" ? "active" : ""}><FontAwesomeIcon icon="fa-solid fa-user-tie" /> Users</NavLink></li>
                <li><NavLink to="/admin/campaign-in-progress" className={location.pathname === "/admin/campaigns-in-progress" ? "active" : ""}><FontAwesomeIcon icon="fa-solid fa-box" /> Campaigns In Progress</NavLink></li>
                <li><NavLink to="/admin/submitted-campaign" className={location.pathname === "/admin/submitted-campaign" ? "active" : ""}><FontAwesomeIcon icon="fa-solid fa-bullhorn" /> Submitted Campaigns</NavLink></li>
                {/* <li><NavLink to="/admin/change-password" className={location.pathname === "/admin/change-password" ? "active" : ""}><FontAwesomeIcon icon="fa-solid fa-unlock-keyhole" /> Change Password</NavLink></li> */}
                <li><NavLink to="/admin/setting" className={location.pathname === "/admin/setting" ? "active" : ""}><FontAwesomeIcon icon="fa-solid fa-gear" /> Edit Admin Details</NavLink></li>
                <li><NavLink to="/admin/dsp-credentials" className={location.pathname === "/admin/dsp-credentials" ? "active" : ""}><FontAwesomeIcon icon="fa-solid fa-gear" /> DSP Credentials</NavLink></li>
                <li><NavLink to="/admin/dsp-reporting" className={location.pathname === "/admin/dsp-reporting" ? "active" : ""}><FontAwesomeIcon icon="fa-solid fa-gear" /> DSP Reporting</NavLink></li>
            </ul>
        </div>
    );
};

export default Aside;
