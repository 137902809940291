import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AWS from "aws-sdk";
import moment from "moment/moment";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ActionButton, displayError } from "../../components/Helper";
import { Context } from '../../components/Context';
import { fetchData, USER_AWS_DETAIL, USER_AWS_UPDATE } from '../../components/Service';

const AwsS3Bucket = () => {
    const [Modal1, setModal1] = useState(false);
    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const [context] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [s3Details, setS3Details] = useState("");
    const [testImage, setTestImage] = useState("")
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const [awsUploadError, setAWSUploadError] = useState("")

    const [checkedImpStatus, setCheckedImpStatus] = useState({
        smadex: false,
        epom: false,
        smartyads: false,
    });

    const [checkedBidStatus, setCheckedBidStatus] = useState({
        epom: false
    });

    // Handler for checkbox change
    const handleImpCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedImpStatus((prev) => ({
        ...prev,
        [name]: checked
        }));
    };

    // Handler for checkbox change
    const handleBidCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedBidStatus((prev) => ({
        ...prev,
        [name]: checked
        }));
    };

    const awsS3Details = (id) => {
        fetchData(USER_AWS_DETAIL + "/" + id, "GET", "", true, false, (res) => {
            if (res.status === "success") {
                setS3Details(res.records)
                setCheckedImpStatus(JSON.parse(res.records.impressionDsp));
                setCheckedBidStatus(JSON.parse(res.records.bidstreamDsp));
            }
        });
    } 

    const saveData = (e) => {
        let formData = new FormData(document.getElementById("user-aws-details"));

        let data = {
            userId: context && context.id ? context.id : 0,
            bucketName: formData.get("bucketName"),
            regionName: formData.get("regionName"),
            accessId: formData.get("accessId"),
            accessKey: formData.get("accessKey"),
            bucketUrl: formData.get("bucketUrl"),
            impressionDsp: JSON.stringify(checkedImpStatus),
            bidstreamDsp: JSON.stringify(checkedBidStatus),
        };

        setTinyloader(true);

        fetchData(USER_AWS_UPDATE, "POST", data, true, false, (res) => {
            setTinyloader(false);
            displayError(res.errors);
            if (res.status === "success") {
                awsS3Details(context.id)
                handleShow1()
            }
        });
    };

    useEffect(() => {
        if(context && context.id) {
            awsS3Details(context.id)
        }
    }, [context])

    const uploadImageBanner = async (e, type) => {
        setFileUploadLoader(true);
        AWS.config.update({
            accessKeyId: s3Details.accessId,
            secretAccessKey: s3Details.accessKey,
        });

        const s3 = new AWS.S3({
            params: { Bucket: s3Details.bucketName },
            region: s3Details.regionName,
        });

        let fileName = moment().unix() + "-" + e.target.files[0].name;

        const params = {
            Bucket: s3Details.bucketName,
            Key: fileName,
            Body: e.target.files[0],
        };


        try {
            const upload = s3
                .putObject(params)
                .on("httpUploadProgress", (evt) => {
                    console.log(
                        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                    );
                })
                .promise();

            await upload;
            setFileUploadLoader(false)
            setAWSUploadError("File uploaded successfully.")
            console.log("File uploaded successfully.");
        } catch (err) {
            setFileUploadLoader(false)
            console.error("Error during file upload:", err);
            if (err.code === "CredentialsError") {
                setAWSUploadError("Invalid credentials. Please check your access key and secret.")
            } else if (err.code === "NoSuchBucket") {
                setAWSUploadError("The specified bucket does not exist.");
            } else if (err.code === "NetworkingError") {
                setAWSUploadError("A network error occurred. Please try again.");
            } else {
                setAWSUploadError("An unknown error occurred.");
            }
        }
        
        setTestImage(fileName)
    }

    return (
        <>
        <Header></Header>
        <div className="main-page-wrapper">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="inner-card-block">
                            <div className="heading-block">
                                <h1>AWS S3 bucket directory</h1>
                                <p>Receiving of log files </p>
                            </div>
                            <Form id="user-aws-details">
                                <Row>
                                    <Col md={12}>
                                        <Form.Group id="form-group-bucketName" className="form-group">
                                            <Form.Label>AWS S3 Bucket Name <span>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="bucketName"
                                                defaultValue={s3Details?.bucketName}
                                            />
                                            <span
                                                id="form-error-bucketName"
                                                className="form-input-error"
                                            ></span>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group id="form-group-regionName" className="form-group">
                                            <Form.Label>AWS S3 Region Name <span>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="regionName"
                                                defaultValue={s3Details?.regionName}
                                            />
                                            <span
                                                id="form-error-regionName"
                                                className="form-input-error"
                                            ></span>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group id="form-group-accessId" className="form-group">
                                            <Form.Label>AWS S3 Access ID <span>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="accessId"
                                                defaultValue={s3Details?.accessId}
                                            />
                                            <span
                                                id="form-error-accessId"
                                                className="form-input-error"
                                            ></span>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group id="form-group-accessKey" className="form-group">
                                            <Form.Label>AWS S3 Access Key <span>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="accessKey"
                                                defaultValue={s3Details?.accessKey}
                                            />
                                            <span
                                                id="form-error-accessKey"
                                                className="form-input-error"
                                            ></span>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group id="form-group-bucketUrl" className="form-group">
                                            <Form.Label>AWS S3 URL <span>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="bucketUrl"
                                                defaultValue={s3Details?.bucketUrl}
                                            />
                                            <span
                                                id="form-error-bucketUrl"
                                                className="form-input-error"
                                            ></span>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="awsselection-option">
                                            <h5>AWS S3 bucket directory for receiving of log files</h5>
                                            <h6>Based on:</h6>
                                            <p>An Impression Served:</p>
                                            <div className="distribution-block">
                                                <label for="epom" className={`dspcheck-block ${checkedImpStatus.epom ? 'selected' : ''}`}>
                                                <input 
                                                    type="checkbox" 
                                                    id="epom" 
                                                    name="epom" 
                                                    checked={checkedImpStatus.epom}
                                                    onChange={handleImpCheckboxChange}
                                                />
                                                <img src={'images/epom-logo.png'} alt="" />
                                                </label>
                                                <label for="smadex" className={`dspcheck-block ${checkedImpStatus.smadex ? 'selected' : ''}`}>
                                                <input 
                                                    type="checkbox" 
                                                    id="smadex" 
                                                    name="smadex" 
                                                    checked={checkedImpStatus.smadex}
                                                    onChange={handleImpCheckboxChange}
                                                />
                                                <img src={'images/smadex-logo.png'} alt="" />
                                                </label>
                                                <label for="smartyads" className={`dspcheck-block ${checkedImpStatus.smartyads ? 'selected' : ''}`}>
                                                <input 
                                                    type="checkbox" 
                                                    id="smartyads" 
                                                    name="smartyads" 
                                                    checked={checkedImpStatus.smartyads}
                                                    onChange={handleImpCheckboxChange}
                                                />
                                                <img src={'images/smartyads-logo.png'} alt="" />
                                                </label>
                                            </div>
                                            <p>Bidstream/Bidrequest:</p>
                                            <div className="distribution-block">
                                                <label for="epombid" className={`dspcheck-block ${checkedBidStatus.epom ? 'selected' : ''}`}>
                                                <input 
                                                    type="checkbox" 
                                                    id="epombid" 
                                                    name="epom"
                                                    checked={checkedBidStatus.epom}
                                                    onChange={handleBidCheckboxChange}
                                                />
                                                <img src={'images/epom-logo.png'} alt="" />
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <ActionButton saveData={saveData} tinyloader={tinyloader} />
                                { s3Details && 
                                    <><Row>
                                    <div className="form-group custom-border-block">
                                        <h5>Test AWS S3 Bucket Connection</h5>
                                        <div className="double-field-block">
                                            <div className="file-upload-block">
                                                <Form.Control type="file" accept="image/*" onChange={(e) => uploadImageBanner(e, "image")}/>
                                                <Button variant="info" className="file-upload-btn">{ fileUploadLoader ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload Images</Button>
                                            </div>
                                        </div>
                                        <span id="form-error-imageBanner" className='form-input-error'></span>
                                        <div className="uploaded-image-show-block">
                                            <img src={process.env.REACT_APP_AWS_S3_URL + testImage} alt="" />
                                        </div>
                                        <p>{awsUploadError}</p>
                                    </div>
                                </Row>
                                </> }
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Modal size="sm" show={Modal1} onHide={handleClose1} centered className="custom-model-block missing-data-block success-data-block">
            <Modal.Body>
            <div className="close-icon-block" onClick={handleClose1}><FontAwesomeIcon icon="fa-solid fa-xmark" /></div>
                <div className="success-campaign-block">
                    <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                    <h3>Successfully Submitted</h3>
                    <p>Your AWS details has been saved successfully.</p>
                </div>
            </Modal.Body>
        </Modal>
        <Footer></Footer>
        </>
        
    );
};

export default AwsS3Bucket;
