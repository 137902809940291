import { Container, Row, Col } from "react-bootstrap";
import Aside from "../Aside";
import Footer from "../Footer";
import Header from "../Header";
import Base from "./Base";
import Loading from "../../modules/pages/Loading";

const Backend = () => {
    return (
        <>
            <Header />
            <div className="main-page-wrapper admin-panel-block">
                <Container fluid>
                    <Row>
                        <Col lg={2} className="admin-aside-block">
                            <Aside></Aside>
                        </Col>
                        <Col lg={10}>
                            <Base />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
            <Loading/>
        </>
    );
};

export default Backend;
