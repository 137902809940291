import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import CampaignData from "../../components/CampaignData";
import { useNavigate } from "react-router-dom";
import { Context } from "../../components/Context";

const SubmitedCampaign = () => {
    const navigate = useNavigate();
    const [, setContext] = useContext(Context);

    const lanchCampaign = () => {
        localStorage.removeItem('__t6t_campaign_id')
        
        setContext(prevValue => ({
            ...prevValue,
            campaignId: "",
            tabName: "main",
        }));

        navigate("/campaign")
    }

    const GoAwsS3Bucket = () => {
        navigate("/aws-s3-bucket")
    }

    return (
        <div className="main-page-wrapper">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="inner-card-block">
                            <div className="getstarted-block">
                                <Button type="button" variant="primary" onClick={() => lanchCampaign()}>
                                    New Campaign
                                </Button>
                                <Button type="button" variant="primary" onClick={() => GoAwsS3Bucket()}>
                                    Reporting
                                </Button>
                                {/* <Button type="button" variant="primary" onClick={() => GoAwsS3Bucket()}>
                                    AWS S3 bucket
                                </Button> */}
                            </div>
                            <div className="submitted-campaign-block">
                                <div className="heading-block">
                                    <h1>Submitted Campaign(s)</h1>
                                    <p>Your submitted campaigns list</p>
                                </div>
                                <div className="submitted-campaign-list">
                                    <CampaignData viewType="user"  dataType="submitted"/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SubmitedCampaign;
