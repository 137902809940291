const Footer = () => {
    return (
        <div className="footer-section">
            <img src= {'/images/logo.png'} alt="Connect X" />
            <p>Copyright © {new Date().getFullYear()} Connect X. All rights reserved.</p>
        </div>
    );
};

export default Footer;
