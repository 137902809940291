import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Button, Spinner, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../components/Context";
import { CAMPAIGN_CREATIVES, fetchData } from "../../../components/Service";
import { ActionButton, displayError, uploadFileToAWSBucket } from "../../../components/Helper";
import { useNavigate } from "react-router-dom";

const Creatives = (props) => {
    const [Modal1, setModal1] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const [Modal2, setModal2] = useState(false);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);

    const navigate = useNavigate()
    const [context, setContext] = useContext(Context)

    const [tinyloader, setTinyloader] = useState(false);
    const [imageBannerType, setImageBannerType] = useState("tag")
    const [videoBannerType, setVideoBannerType] = useState("tag")
    const [imageBanner, setImageBanner] = useState("")
    const [videoBanner, setVideoBanner] = useState("")
    // const [nativeBannerIcon, setNativeBannerIcon] = useState("native-banner-icon.jpg")
    const [nativeBannerIcon, setNativeBannerIcon] = useState("")
    const [nativeBannerImage, setNativeBannerImage] = useState("")
    const [fileUploadLoader, setFileUploadLoader] = useState(false)

    const setContinue = () => {
        handleClose2()
        props.makeTabAcive("devices")
    }

    const saveData = (e) => {
        let formData = new FormData(document.getElementById('campaign-creatives'));

        if(imageBanner === "" && videoBanner === "" && nativeBannerImage === "") {
            handleShow2();
            return false;
        }
        
        let data = {
            userId: (context && context.id) ? context.id : 0,
            campaignId: context && context.campaignId ? context.campaignId : "",
            bannerImageType: imageBannerType,
            bannerImage: imageBanner,
            bannerVideoType: videoBannerType,
            bannerVideo: videoBanner,
            bannerNativeIcon: nativeBannerIcon,
            bannerNativeImage: nativeBannerImage,
            bannerTitle: formData.get("bannerTitle"),
            bannerDescription: formData.get("bannerDescription"),
            bannerLandingPageUrl: formData.get("bannerLandingPageUrl"),
            bannerComments: formData.get("bannerComments")
        }

        setTinyloader(true)

        fetchData(CAMPAIGN_CREATIVES, 'POST', data, true, false, (res) => {
            setTinyloader(false);
            displayError(res.errors);
            if (res.status === "success") {
                setContext(prevValue => ({
                    ...prevValue,
                    campaignId: res?.records?.id,
                    tabName: res?.records?.formNamePath
                }))
                localStorage.setItem('__t6t_campaign_id', res?.records?.id)
                props.makeTabAcive("", res?.records?.id)
            }
        });
    }

    const uploadImageBanner = async (e, type) => {
        setImageBannerType(type)
        if(type === "image"){
            setFileUploadLoader("banner-image")
            let file = await uploadFileToAWSBucket(e)
            setFileUploadLoader(false)
        
            setImageBanner(file)
        } else {
            setImageBanner(e.target.value)
        }
    }

    const uploadVideoBanner = async (e, type) => {
        setVideoBannerType(type)
        if(type === "video"){
            setFileUploadLoader("banner-video")
            let file = await uploadFileToAWSBucket(e)
            setFileUploadLoader(false)
        
            setVideoBanner(file)
        } else {
            setVideoBanner(e.target.value)
        }
    }

    const uploadNativeBannerIcon = async (e) => {
        setFileUploadLoader("native-icon")
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        setNativeBannerIcon(file)
    }

    const uploadNativeBannerImage = async (e) => {
        setFileUploadLoader("native-banner")
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        setNativeBannerImage(file)
    }

    useEffect(() => {
        if(props && props.campaignData) {
            setImageBannerType(props.campaignData.bannerImageType ? props.campaignData.bannerImageType : "tag")
            setImageBanner(props.campaignData.bannerImage ? props.campaignData.bannerImage : "")

            setVideoBannerType(props.campaignData.bannerVideoType ? props.campaignData.bannerVideoType : "tag")
            setVideoBanner(props.campaignData.bannerVideo ? props.campaignData.bannerVideo : "")

            // setNativeBannerIcon(props.campaignData.bannerNativeIcon ? props.campaignData.bannerNativeIcon : "native-banner-icon.jpg")
            setNativeBannerIcon(props.campaignData.bannerNativeIcon ? props.campaignData.bannerNativeIcon : "")
            setNativeBannerImage(props.campaignData.bannerNativeImage ? props.campaignData.bannerNativeImage : "")
        }
    }, [props])

    return (
        <>
            <div className="tab-inner-content">
                <Form id="campaign-creatives">
                    <Row>
                        <Col lg={7}>
                            <div className="heading-wth-subheading">
                                <h4 className="form-heading">Banners & Landingpage</h4>
                                <p className="subheading">Upload banners you want to be served on phones/devices and enter the landingpage for if a person clicks on the banner.</p>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="aside-list-block aside-header-creative">
                                <h4>Recommended dimensions:</h4>
                            </div>
                        </Col>
                    </Row>
                    <div className="creatives-section">
                        <div className="creatives-seperate-block">
                        <Row>
                            <Col lg={7}>
                                <div className="form-group">
                                    <label className="form-label">Image Banner</label>
                                    <div className="double-field-block">
                                        <div className="file-upload-block">
                                            <Form.Control type="file" accept="image/*" onChange={(e) => uploadImageBanner(e, "image")}/>
                                            <Button variant="light" className="file-upload-btn">{ fileUploadLoader === "banner-image" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload Images</Button>
                                        </div>
                                        <div className="orblock">or</div>
                                        <div className="cust-input-field-block">
                                            <Form.Control type="text" placeholder="Enter image tag" onChange={(e) => uploadImageBanner(e, "tag")}  defaultValue={imageBanner ? (imageBannerType === "image" ? process.env.REACT_APP_AWS_S3_URL + imageBanner : imageBanner) : ""}/>
                                        </div>
                                    </div>
                                    <span id="form-error-imageBanner" className='form-input-error'></span>
                                    <div className="uploaded-image-show-block">
                                        <img src={imageBanner ? (imageBannerType === "image" ? process.env.REACT_APP_AWS_S3_URL + imageBanner : imageBanner) : `../images/image-placeholder.jpg`} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5}>
                            <div className="left-side-block">
                                <div className="aside-list-block">
                                <p>
                                    <b>Image Dimensions:</b> 320x50px, 300x250px, 320x480px <br />
                                    <b>Image Formats:</b> png, jpg <br />
                                    <b>Image Size (max):</b> 120kb <br />
                                    <b>Image tag:</b> Javascript, to be hosted on a 3rd party server
                                </p>
                                </div>
                            </div>
                            </Col>
                        </Row>
                        </div>
                        <div className="creatives-seperate-block">
                        <Row>
                            <Col lg={7}>
                                <div className="form-group custom-border-block">
                                    <label className="form-label">Video Banner</label>
                                    <div className="double-field-block">
                                        <div className="file-upload-block">
                                            <Form.Control type="file" accept="video/*" onChange={(e) => uploadVideoBanner(e, "video")}/>
                                            <Button variant="light" className="file-upload-btn">{ fileUploadLoader === "banner-video" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload Video</Button>
                                        </div>
                                        <div className="orblock">or</div>
                                        <div className="cust-input-field-block">
                                            <Form.Control type="text" placeholder="Enter video tag" onChange={(e) => uploadVideoBanner(e, "tag")}  defaultValue={videoBanner ? (videoBannerType === "video" ? process.env.REACT_APP_AWS_S3_URL + videoBanner : videoBanner) : ""}/>
                                        </div> 
                                    </div>
                                    <span id="form-error-videoBanner" className='form-input-error'></span>
                                </div>
                            </Col>
                            <Col lg={5}>
                            <div className="left-side-block">
                                <div className="aside-list-block">
                                    <p>
                                    <b>Video Dimensions:</b> 300x250, 320x480, 480x320 <br />
                                    mp4 video file or the tag itself (VAST, iFrame, VPaid) <br />
                                    <b>Max file size:</b> 2mb, duration 5-30 seconds
                                    </p>
                                </div>
                            </div>
                            </Col>
                        </Row>
                        </div>
                        <div className="creatives-seperate-block">
                        <Row>
                            <Col lg={7}>
                            <div className="form-group">
                                <label className="form-label">Native Banner</label>
                                <div className="double-file-upload-block">
                                    <div className="file-upload-block me-4">
                                        <Form.Control type="file" accept="image/*" onChange={(e) => uploadNativeBannerIcon(e)}/>
                                        <Button variant="light" className="file-upload-btn">{ fileUploadLoader === "native-icon" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload  Icon</Button>
                                        <span id="form-error-nativeBannerIcon" className='form-input-error'></span>
                                    </div>
                                    <div className="file-upload-block">
                                        <Form.Control type="file" accept="image/*" onChange={(e) => uploadNativeBannerImage(e)}/>
                                        <Button variant="light" className="file-upload-btn">{ fileUploadLoader === "native-banner" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload  Main Image</Button>
                                        <span id="form-error-nativeBannerImage" className='form-input-error'></span>
                                    </div>
                                </div>
                                <div className="uploaded-image-show-block">
                                    <img src={nativeBannerIcon ? process.env.REACT_APP_AWS_S3_URL + nativeBannerIcon : `../images/image-placeholder.jpg`} alt="" />
                                    <img src={nativeBannerImage ? process.env.REACT_APP_AWS_S3_URL + nativeBannerImage : `../images/image-placeholder.jpg`} alt="" />
                                </div>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Title (max 30 symbols)</Form.Label>
                                        <Form.Control type="text" name="bannerTitle" defaultValue={props?.campaignData?.bannerTitle}/>
                                        <span id="form-error-bannerTitle" className='form-input-error'></span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Description (max 45 symbols)</Form.Label>
                                        <Form.Control type="text" name="bannerDescription" defaultValue={props?.campaignData?.bannerDescription}/>
                                        <span id="form-error-bannerDescription" className='form-input-error'></span>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Landing Page/URL</Form.Label>
                                        <Form.Control type="text" name="bannerLandingPageUrl" defaultValue={props?.campaignData?.bannerLandingPageUrl}/>
                                        <span id="form-error-bannerLandingPageUrl" className='form-input-error'></span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="form-group">
                                <Form.Label>Additional Comments or Questions</Form.Label>
                                <Form.Control as="textarea" rows={3} name="bannerComments" defaultValue={props?.campaignData?.bannerComments}/>
                                <span id="form-error-bannerComments" className='form-input-error'></span>
                            </Form.Group>
                            </Col>
                            <Col lg={5}>
                            <div className="left-side-block">
                                <div className="aside-list-block">
                                    <p>
                                        <b>Native:</b> <br />
                                        <b>Icon Size:</b> 192x192 <br />
                                        <b>Main Image Size:</b> 492x328 <br />
                                        <b>Title:</b> 30 symbols max <br />
                                        <b>Description:</b> 45 symbols max <br />
                                        <b>Formats:</b> jpg, png
                                    </p>
                                    <Button variant="light" className="btn-medium" onClick={handleShow1}>What is a Native Banner?</Button>
                                    <img src= {'../images/banner-image.jpg'} alt="Connect X" />
                                </div>
                            </div>
                            </Col>
                        </Row>
                        </div>
                    </div>
                    <ActionButton saveData={saveData} tinyloader={tinyloader} />
                </Form>
            </div>
            <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block">
                <Modal.Header closeButton>
                    <div className="model-head-logo"><img src={'/images/logo.png'} alt="Connect X"/></div>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-heding">What is a banner?</div>
                    <p>A native banner is an advertising format designed to seamlessly blend with the content of the website or app where it appears, making it look more like part of the natural layout rather than a traditional ad. It often resembles the header of a news article and may include a label like "sponsored" to indicate it's a paid promotion.</p>
                    <p><b>Technical Specifications:</b></p>
                    <ul>
                        <li><b>Icon Size:</b> 192x192 pixels</li>
                        <li><b>Main Image Size:</b> 492x328 pixels</li>
                        <li><b>Title:</b> Up to 30 characters</li>
                        <li><b>Description:</b> Up to 45 characters</li>
                        <li><b>Formats:</b> JPG, PNG</li>
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal size="sm" show={Modal2} onHide={handleClose2} centered className="custom-model-block missing-data-block">
                <Modal.Body>
                <div className="close-icon-block" onClick={handleClose2}><FontAwesomeIcon icon="fa-solid fa-xmark" /></div>
                    <div className="success-campaign-block">
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                        <h3>Missing Data</h3>
                        <p>Mandatory data/input is missing</p>
                        <p>Atleast 1 of 3 options (image banner, video banner, native banner) should be uploaded.</p>
                        <Button variant="primary" className="btn-medium" onClick={setContinue}>Continue regardless</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Creatives;
