import { useContext } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Context } from './Context';

const Header = () => {
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context)

    const logout = (e) => {
        e.preventDefault()
        
        localStorage.removeItem('__t6ttoken')
        localStorage.removeItem('__t6t_campaign_id')
        
        setContext("")

        navigate("/")
    }

    return(
        <Navbar collapseOnSelect expand="lg" className="header-section">
        <Container fluid>
          <Navbar.Brand onClick={() => navigate((context && context.role === "admin") ? "/admin/dashboard" : "/campaign/submited")}><img src= {'/images/logo.png'} alt="Connect X" /></Navbar.Brand>
          <Navbar.Toggle  aria-controls="navbarScroll"/>
          <Navbar.Collapse className="justify-content-end" id="navbarScroll">
            <Nav>
            <NavDropdown title={<span className="profile-content-menu">Hi, <i>{context && context.firstName} {context && context.lastName}</i> <b>{context ? ((context.firstName)[0]).toUpperCase() : "T"}</b></span>} id="basic-nav-dropdown" className="profile-dropdown-menu">
                <NavDropdown.Item className="user-profile-menu">
                  <div className="dd-profile-menu"><span>{context ? ((context.firstName)[0]).toUpperCase() : "T"}</span> {context && context.firstName} {context && context.lastName}</div>
                </NavDropdown.Item>
                {/* <NavDropdown.Item>Saved Campaign</NavDropdown.Item> */}
                <NavDropdown.Item className="logout-menu" onClick={logout}>
                    <div className="btn btn-secondary btn-medium">Logout</div>
                </NavDropdown.Item>
            </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
};

export default Header;
