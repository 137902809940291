export const ENDPOINT = process.env.REACT_APP_ENDPOINT

export const LOGIN = "/auth/login"
export const AUTH_USER_DETAIL = "/auth/auth-user-detail"
export const CHANGE_PASSWORD = "/auth/change-password"

export const USER_LIST = "/users/users-list"
export const USER_CREATE = "/users/users-create"
export const USER_DETAIL = "/users/users-details"
export const USER_UPDATE = "/users/users-update"
export const USER_DELETE = "/users/users-delete"

export const DSP_CREDENTIAL_UPDATE = "/users/credentials"
export const DSP_CREDENTIAL_DETAIL = "/users/credentials-details"

export const USER_AWS_UPDATE = "/users/save-aws"
export const USER_AWS_DETAIL = "/users/aws-details"

export const DASHBOARD_STATISTICS = "/users/dashboard-statistics"

export const CAMPAIGN_LIST = "/campaign/campaign-list"
export const CAMPAIGN_IN_PROGRESS_LIST = "/campaign/campaign-in-progress-list"
export const CAMPAIGN_DETAIL_FULL = "/campaign/campaign-detail-full"
export const GET_CAMPAIGN_DETAIL = "/campaign/campaign-detail"
export const CAMPAIGN_MAIN = "/campaign/campaign-main"
export const CAMPAIGN_BUDGET = "/campaign/campaign-budget"
export const CAMPAIGN_GEO = "/campaign/campaign-geo"
export const CAMPAIGN_CREATIVES = "/campaign/campaign-creatives"
export const CAMPAIGN_DEVICES = "/campaign/campaign-devices"
export const CAMPAIGN_DISTRIBUTION = "/campaign/campaign-distribution"
export const CAMPAIGN_DELETE = "/campaign/campaign-delete"

export const REPORT_EPOM = "/report/epom-report"
export const REPORT_MEDIASMART = "/report/mediasmart-report"
export const REPORT_SMADEX = "/report/smadex-report"
export const REPORT_SMARTYADS = "/report/smartyads-report"

export const MS_USER_DETAIL = "/media-smart/ms-users-details"
export const MS_USER_UPDATE = "/media-smart/ms-users-update"

export const fetchData = async (url, method, data, token, process, res, err, processimg = false) => {
    let headers = {
        'Accept': 'application/json',
    }

    if (process) {
        headers = { ...headers, 'contentType': false, 'processData': false }
    } else {
        headers = { ...headers, 'Content-Type': 'application/json' }
    }

    if (token) {
        let TOKEN = localStorage.getItem("__t6ttoken");
        headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if (data) {
        request = { ...request, 'body': process ? data : JSON.stringify(data) }
    }
  
    await fetch(`${ENDPOINT}${url}`, request).then((response) => processimg === "text" ? response.text() : (processimg === "blob"? response.blob(): response.json() )).then((json) => {
        if (json.message === "unauthenticated") {
            localStorage.removeItem("__t6ttoken");
        } else {
            res(json)
        }
    })
    .catch((error) => { console.log(error) });
}