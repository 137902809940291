import React, { useContext, useEffect, useState } from "react";
import { Form, Table, InputGroup, Modal, Button } from "react-bootstrap";
import { CAMPAIGN_BUDGET, fetchData } from "../../../components/Service";
import { ActionButton, displayError } from "../../../components/Helper";
import { Context } from "../../../components/Context";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Budget = (props) => {
    const navigate = useNavigate()
    const [Modal1, setModal1] = useState(false);
    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const [budgetErrorTotal, setBudgetErrorTotal] = useState(false);
    const [budgetErrorDaily, setBudgetErrorDaily] = useState(false);

    const limitBudget = props && props.campaignData && props.campaignData.limitBudget ? JSON.parse(props?.campaignData?.limitBudget) : ""
    const limitImpressions = props && props.campaignData && props.campaignData.limitImpressions ? JSON.parse(props?.campaignData?.limitImpressions) : ""
    const limitClicks = props && props.campaignData && props.campaignData.limitClicks ? JSON.parse(props?.campaignData?.limitClicks) : ""
    
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [budgetStatus, setBudgetStatus] = useState(true);
    const [impStatus, setImpStatus] = useState(false);
    const [clickStatus, setClickStatus] = useState(false);

    const setContinue = () => {
        handleClose1()
        props.makeTabAcive("geo")
    }

    const saveData = (e) => {
        let formData = new FormData(document.getElementById("campaign-budget"));
        console.log(formData.get("limitBudgetDaily") === "0")
        if((formData.get("limitBudgetTotal") === "" || formData.get("limitBudgetTotal") === "0") || (formData.get("limitBudgetDaily") === "" || formData.get("limitBudgetDaily") === "0")) {
            if(formData.get("limitBudgetTotal") === "" || formData.get("limitBudgetTotal") === "0") {
                setBudgetErrorTotal(true)
            } else {
                setBudgetErrorTotal(false)
            }

            if(formData.get("limitBudgetDaily") === "" || formData.get("limitBudgetDaily") === "0") {
                setBudgetErrorDaily(true)
            } else {
                setBudgetErrorDaily(false)
            }

            handleShow1();
            return false;
        }

        let data = {
            userId: context && context.id ? context.id : 0,
            campaignId: context && context.campaignId ? context.campaignId : "",
            limitBudget: {limitBudget: budgetStatus, limitBudgetTotal: formData.get("limitBudgetTotal"), limitBudgetDaily: formData.get("limitBudgetDaily"), limitBudgetHourly: formData.get("limitBudgetHourly")},
            limitImpressions: {limitImpressions: impStatus, limitImpressionsTotal: formData.get("limitImpressionsTotal"), limitImpressionsDaily: formData.get("limitImpressionsDaily"), limitImpressionsHourly: formData.get("limitImpressionsHourly")},
            limitClicks:  {limitClicks: clickStatus, limitClicksTotal: formData.get("limitClicksTotal"), limitClicksDaily: formData.get("limitClicksDaily"), limitClicksHourly: formData.get("limitClicksHourly")},
        };

        setTinyloader(true);

        fetchData(CAMPAIGN_BUDGET, "POST", data, true, false, (res) => {
            setTinyloader(false);
            displayError(res.errors);
            if (res.status === "success") {
                setContext(prevValue => ({
                    ...prevValue,
                    campaignId: res?.records?.id,
                    tabName: res?.records?.formNamePath
                }))
                localStorage.setItem('__t6t_campaign_id', res?.records?.id)
                props.makeTabAcive("", res?.records?.id)
            }
        });
    };

    useEffect(() => {
        setBudgetStatus(limitBudget ? limitBudget.limitBudget : true);
        setImpStatus(limitImpressions ? limitImpressions.limitImpressions : false);
        setClickStatus(limitClicks ? limitClicks.limitClicks: false);
    }, [props])

    return (
        <>
            <div className="tab-inner-content">
                <Form id="campaign-budget">
                    <h4 className="form-heading">Limits</h4>
                    <Table className="custom-limits-table" responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Total</th>
                                <th>Daily</th>
                                <th>Hourly</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Check className={(budgetErrorTotal || budgetErrorDaily) && "form-group-error"}
                                        name="limitBudget"
                                        type="checkbox"
                                        id="budget"
                                        label="Budget *"
                                        checked={budgetStatus}
                                    />
                                </td>
                                <td>
                                    <InputGroup className={budgetErrorTotal && "input-group-error"}>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control
                                            type="number"
                                            name="limitBudgetTotal"
                                            placeholder="Unlimited"
                                            defaultValue={limitBudget.limitBudgetTotal}
                                        />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup className={budgetErrorDaily && "input-group-error"}>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control
                                            type="number"
                                            name="limitBudgetDaily"
                                            placeholder="Unlimited"
                                            defaultValue={limitBudget.limitBudgetDaily}
                                        />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup>
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control
                                            type="number"
                                            name="limitBudgetHourly"
                                            placeholder="Unlimited"
                                            defaultValue={limitBudget.limitBudgetHourly}
                                        />
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        name="limitImpressions"
                                        id="impressions"
                                        label="Impressions"
                                        checked={impStatus}
                                        onChange={(e) => {
                                            setImpStatus(e.target.checked)
                                            if(!e.target.checked) {
                                                document.querySelector('input[name="limitImpressionsTotal"]').value = '';
                                                document.querySelector('input[name="limitImpressionsDaily"]').value = '';
                                                document.querySelector('input[name="limitImpressionsHourly"]').value = '';
                                            }
                                        }}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="limitImpressionsTotal"
                                        placeholder="Unlimited"
                                        disabled={!impStatus}
                                        defaultValue={limitImpressions.limitImpressionsTotal}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="limitImpressionsDaily"
                                        placeholder="Unlimited"
                                        disabled={!impStatus}
                                        defaultValue={limitImpressions.limitImpressionsDaily}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="limitImpressionsHourly"
                                        placeholder="Unlimited"
                                        disabled={!impStatus}
                                        defaultValue={limitImpressions.limitImpressionsHourly}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        name="limitClicks"
                                        id="clicks"
                                        label="Clicks"
                                        checked={clickStatus}
                                        onChange={(e) => {
                                            setClickStatus(e.target.checked)
                                            if(!e.target.checked) {
                                                document.querySelector('input[name="limitClicksTotal"]').value = '';
                                                document.querySelector('input[name="limitClicksDaily"]').value = '';
                                                document.querySelector('input[name="limitClicksHourly"]').value = '';
                                            }
                                        }}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="limitClicksTotal"
                                        placeholder="Unlimited"
                                        disabled={!clickStatus}
                                        defaultValue={clickStatus ? limitClicks.limitClicksTotal : ""}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="limitClicksDaily"
                                        placeholder="Unlimited"
                                        disabled={!clickStatus}
                                        defaultValue={limitClicks.limitClicksDaily}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        name="limitClicksHourly"
                                        placeholder="Unlimited"
                                        disabled={!clickStatus}
                                        defaultValue={limitClicks.limitClicksHourly}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <ActionButton saveData={saveData} tinyloader={tinyloader} />
                </Form>
            </div>
            <Modal size="sm" show={Modal1} onHide={handleClose1} centered className="custom-model-block missing-data-block">
                <Modal.Body>
                <div className="close-icon-block" onClick={handleClose1}><FontAwesomeIcon icon="fa-solid fa-xmark" /></div>
                    <div className="success-campaign-block">
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                        <h3>Missing Data</h3>
                        <p>Mandatory data/input is missing</p>
                        <Button variant="primary" className="btn-medium" onClick={setContinue}>Continue regardless</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Budget;
