import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import Main from "./partials/Main";
import Budget from "./partials/Budget";
import Geo from "./partials/Geo";
import Creatives from "./partials/Creatives";
import Devices from "./partials/Devices";
import Distribution from "./partials/Distribution";
import { useContext, useEffect, useState } from "react";
import { PageLoader } from "../../components/Helper";
import { CAMPAIGN_DETAIL_FULL, fetchData } from "../../components/Service";
import { Context } from "../../components/Context";

const Campaign = () => {
    const [context] = useContext(Context)
    const [activeTab, setActiveTab] = useState("main")
    const [data, setData] = useState()

    const makeMainTabAcive = (tabName) => {
        if(context && context.campaignId){
            getCampaignDetails(context.campaignId, tabName)
        } else {
            setActiveTab(tabName)
        }
    }

    const makeTabAcive = (tabName = "", id = "") => {
        if(id) {
            getCampaignDetails(id)
        } else {
            setActiveTab(tabName)
        }
    }

    const getCampaignDetails = (id, tabName = "") => {
        PageLoader(true);
        fetchData(CAMPAIGN_DETAIL_FULL, 'POST', {campaignId: id}, true, false, (res) => {
            PageLoader(false);
            if (res.status === "success") {
                setActiveTab(tabName ? tabName : res?.records?.formNamePath)
                setData(res.records)
            } else {
                // displayError(res.errors)
            }
        });
    }

    useEffect(() => {
        if(context && context.campaignId){
            getCampaignDetails(context.campaignId)
        }
    }, [])

    return (
        <div className="main-page-wrapper">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="pulse-tab-section">
                            <Tabs
                                id="pulse-tab-block"
                                fill
                                activeKey={activeTab}
                                onSelect={(k) => makeMainTabAcive(k)}
                            >
                                <Tab
                                    eventKey="main"
                                    title={
                                        <span className="tab-head-blk">
                                            <b>
                                                <FontAwesomeIcon icon="fa-solid fa-info" />
                                            </b>{" "}
                                            Main
                                        </span>
                                    }
                                >
                                    <Main makeTabAcive={makeTabAcive} campaignData={data}></Main>
                                </Tab>
                                <Tab
                                    eventKey="budget"
                                    title={
                                        <span className="tab-head-blk">
                                            <b>
                                                <FontAwesomeIcon icon="fa-solid fa-coins" />
                                            </b>{" "}
                                            Budget
                                        </span>
                                    }
                                >
                                    <Budget makeTabAcive={makeTabAcive} campaignData={data}></Budget>
                                </Tab>
                                <Tab
                                    eventKey="geo"
                                    title={
                                        <span className="tab-head-blk">
                                            <b>
                                                <FontAwesomeIcon icon="fa-solid fa-earth-americas" />
                                            </b>{" "}
                                            GEO
                                        </span>
                                    }
                                >
                                    <Geo makeTabAcive={makeTabAcive} campaignData={data}></Geo>
                                </Tab>
                                <Tab
                                    eventKey="creatives"
                                    title={
                                        <span className="tab-head-blk">
                                            <b>
                                                <FontAwesomeIcon icon="fa-solid fa-images" />
                                            </b>{" "}
                                            Creatives
                                        </span>
                                    }
                                >
                                    <Creatives makeTabAcive={makeTabAcive} campaignData={data}></Creatives>
                                </Tab>
                                <Tab
                                    eventKey="devices"
                                    title={
                                        <span className="tab-head-blk">
                                            <b>
                                                <FontAwesomeIcon icon="fa-solid fa-mobile-screen-button" />
                                            </b>{" "}
                                            Devices
                                        </span>
                                    }
                                >
                                    <Devices makeTabAcive={makeTabAcive} campaignData={data}></Devices>
                                </Tab>
                                <Tab
                                    eventKey="distribution"
                                    title={
                                        <span className="tab-head-blk">
                                            <b>
                                                <FontAwesomeIcon icon="fa-solid fa-network-wired" />
                                            </b>{" "}
                                            Distribution
                                        </span>
                                    }
                                >
                                    <Distribution makeTabAcive={makeTabAcive} campaignData={data}></Distribution>
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Campaign;
