import { Container, Row, Col, Table, Spinner } from 'react-bootstrap';
import Footer from '../../components/Footer';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Context } from '../../components/Context';
import { DASHBOARD_STATISTICS, fetchData } from '../../components/Service';
import moment from 'moment/moment';

const Dashboard = () => {
    const [context] = useContext(Context)
    const [statisticsData, setStatisticsData] = useState("")
    const [countLoader, setCountLoader] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setCountLoader(true);

        fetchData(DASHBOARD_STATISTICS, 'GET', "", true, false, (res) => {
            setCountLoader(false);
            if (res.status === "success") {
                setStatisticsData(res.records)
            } else {
                // displayError(res.errors)
            }
        });
    }, [])

    return (
        <div className="dashboard-content-block">
            <div className="dashbaord-heading">
                Dashboard
            </div>
            <div className="admin-table-block">
                <Table responsive bordered hover>
                    <tbody>
                        <tr>
                            <th>Name:</th>
                            <td>{context && context.firstName} {context && context.lastName}</td>
                        </tr>
                        <tr>
                            <th>Email Id:</th>
                            <td>{ context && context.email }</td>
                        </tr>
                        <tr>
                            <th>Phone Number:</th>
                            <td>{ context && context.phone }</td>
                        </tr>
                        <tr>
                            <th>Last Login:</th>
                            <td>{context && context.lastLogin ? moment(context.lastLogin).format("Do MMM YY, h:mm a") : "N/A"}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <div className="dashboard-card-block">
                <Row>
                    <Col md={4}>
                        <div className="dashboard-card" onClick={() => navigate("/admin/users")}>
                            <h2>{countLoader ? <Spinner animation="border" /> : (statisticsData && statisticsData.userCount)}</h2>
                            <h4>Users</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="dashboard-card" onClick={() => navigate("/admin/campaign-in-progress")}>
                            <h2>{countLoader ? <Spinner animation="border" /> : (statisticsData && statisticsData.campaignInProgress)}</h2>
                            <h4>Campaigns In Progress</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="dashboard-card" onClick={() => navigate("/admin/submitted-campaign")}>
                            <h2>{countLoader ? <Spinner animation="border" /> : (statisticsData && statisticsData.campaignSubmitted)}</h2>
                            <h4>Campaigns Submitted</h4>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Dashboard;