import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Modal, Spinner, } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { CAMPAIGN_DELETE, CAMPAIGN_DETAIL_FULL, CAMPAIGN_IN_PROGRESS_LIST, CAMPAIGN_LIST, fetchData } from './Service';
import { Context } from './Context';
import { PageLoader } from './Helper';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";

const CampaignData = (props) => {
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context)
    const [Modal1, setModal1] = useState(false);
    const [tinyLoader, setTinyLoader] = useState(false)

    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const [dataList, setDataList] = useState([])
    const [data, setData] = useState()

    const getUsersList = (e) => {
        fetchData((props.dataType === "submitted" ? CAMPAIGN_LIST : CAMPAIGN_IN_PROGRESS_LIST) + (props.viewType === "user" ? "?userId=" + context.id : ""), 'GET', "", true, false, (res) => {
            if (res.status === "success") {
                setDataList(res.records)
            } else {
                // displayError(res.errors)
            }
        });
    }

    const viewCampaign = (id) => {
        PageLoader(true);
        fetchData(CAMPAIGN_DETAIL_FULL, 'POST', {campaignId: id}, true, false, (res) => {
            if (res.status === "success") {
                setData(res.records)
                handleShow1()
                PageLoader(false);
            } else {
                // displayError(res.errors)
            }
        });
    }

    const editCampaign = (id) => {
        localStorage.setItem('__t6t_campaign_id', id)
        setContext(prevValue => ({
            ...prevValue,
            campaignId: id
        }))
        navigate("/campaign")
    }

    const deleteCampaign = (id) => {
        setTinyLoader("delete");

        fetchData(CAMPAIGN_DELETE + "/" + id, 'DELETE', "", true, false, (res) => {
            setTinyLoader(false);
            if (res.status === "success") {
                getUsersList()
            } else {
                // displayError(res.errors)
            }
        });
    }

    useEffect(() => {
        if(context && context.id){
            getUsersList()
        }
    }, [])

    return (
        <>
            <Table responsive bordered hover>
                <thead>
                    <tr>
                        <th width="110px" className="text-center">Campaign ID</th>
                        <th>Campaign Name</th>
                        {props.viewType === "admin" && <th>User's Name</th> }
                        {props.viewType === "admin" && <th>User's Email</th> }
                        <th width="180px">Date</th>
                        <th width="130px" className="text-center">Status</th>
                        <th width="130px" className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {dataList && dataList.length ? dataList.map((campaign, i) => (
                        <tr key={campaign.id}>
                            <td className="text-center">{campaign.id}</td>
                            <td>{campaign.name}</td>
                            {props.viewType === "admin" && <td>{campaign.User && campaign.User.firstName} {campaign.User && campaign.User.lastName}</td> }
                            {props.viewType === "admin" && <td>{campaign.User && campaign.User.email}</td> }
                            <td>{ moment(campaign.createdAt).format("Do MMM YY, h:mm a") }</td>
                            <td className="text-center">{_.startCase(campaign.status)}</td>
                            <td className="text-center">
                            <Button variant="view" onClick={() => viewCampaign(campaign.id)}><FontAwesomeIcon icon="fa-solid fa-eye" /></Button>
                            <Button variant="edit" onClick={() => editCampaign(campaign.id)}><FontAwesomeIcon icon="fa-solid fa-pencil" /></Button>
                            {props.viewType === "admin" && <Button variant="delete" onClick={() => deleteCampaign(campaign.id)}>{ tinyLoader === "delete" ? <Spinner animation="grow" size="sm" /> : <FontAwesomeIcon icon="fa-solid fa-trash-can" /> }</Button>}
                            </td>
                        </tr>
                    )) : <tr><td colSpan="7" className='text-center text-danger'>No Campaign Found</td></tr>}
                </tbody>
            </Table>
                        
            <Modal centered show={Modal1} onHide={handleClose1} className="custom-model-block saved-campaign-model">
                <Modal.Header closeButton>
                    <div className="model-head-logo"><img src={'/images/logo.png'} alt="Connect X"/></div>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-heding">Campaign <span className="mod-status">{data && data.status === "in-progress" ? "In Progress" : "Completed"}</span></div>
                    {data && <div className="campaign-section">
                        <div className="section-list-block">
                            <h3>Main</h3>
                            <div className="cam-sub-head-labin-block">
                                <h5>Basic information</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Name</div> 
                                    <div className="campaign-input-blk">{data.name ? data.name : ""}</div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Campaign</div> 
                                    <div className="campaign-input-blk">{data.campaign ? data.campaign : ""}</div>
                                </div>
                            </div>
                            <div className="cam-sub-head-labin-block">
                                <h5>Schedule</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Start date (UTC)</div> 
                                    <div className="campaign-input-blk">{data.startDate ? moment(data.startDate).format("DD/MM/YYYY") : ""}</div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">End date (UTC)</div> 
                                    <div className="campaign-input-blk">{data?.endDate ? moment(data.endDate).format("DD/MM/YYYY") : ""}</div>
                                </div>
                            </div>
                            <div className="cam-sub-head-labin-block">
                                <h5>Dayparts</h5>
                                {data.schedule && JSON.parse(data.schedule).length ? JSON.parse(data.schedule).map((i, v) => (
                                    <div className="cam-labin-blk">
                                        <div className="campaign-label-blk">{i.weekType}</div> 
                                        <div className="campaign-input-blk">From {i.weekTypeFH}:{i.weekTypeFM} To {i.weekTypeTH}:{i.weekTypeTM}</div>
                                    </div>
                                )) : ""}
                            </div>
                            <div className="cam-sub-head-labin-block">
                                <h5>Advertiser</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Representative category</div> 
                                    <div className="campaign-input-blk">
                                        {data.representativeCategory ? <>{JSON.parse(data.representativeCategory).label}</> : ""}
                                    </div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Domain</div> 
                                    <div className="campaign-input-blk"><a href={data.domain ? data.domain : ""} target="_blank" rel="noreferrer">{data.domain ? data.domain : ""}</a></div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Bundle</div> 
                                    <div className="campaign-input-blk">{data.bundle ? data.bundle : ""}</div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Landing page URL</div> 
                                    <div className="campaign-input-blk"><a href={data.landingPageUrl ? data.landingPageUrl : ""} target="_blank" rel="noreferrer">{data.landingPageUrl ? data.landingPageUrl : ""}</a></div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="section-list-block">
                            <h3>Budget</h3>
                            <div className="cam-sub-head-labin-block">
                                <h5>Limits</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Budget</div> 
                                    <div className="campaign-input-blk">
                                        Total: {data.limitBudget && JSON.parse(data.limitBudget).limitBudgetTotal ? <>{JSON.parse(data.limitBudget).limitBudgetTotal}</> : "Unlimited"}, 
                                        Daily: {data.limitBudget && JSON.parse(data.limitBudget).limitBudgetDaily ? <>{JSON.parse(data.limitBudget).limitBudgetDaily}</> : "Unlimited"}, 
                                        Hourly: {data.limitBudget && JSON.parse(data.limitBudget).limitBudgetHourly ? <>{JSON.parse(data.limitBudget).limitBudgetHourly}</> : "Unlimited"}
                                    </div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Impressions</div> 
                                    <div className="campaign-input-blk">
                                        Total: {data.limitImpressions && JSON.parse(data.limitImpressions).limitImpressionsTotal ? <>{JSON.parse(data.limitImpressions).limitImpressionsTotal}</> : "Unlimited"}, 
                                        Daily: {data.limitImpressions && JSON.parse(data.limitImpressions).limitImpressionsDaily ? <>{JSON.parse(data.limitImpressions).limitImpressionsDaily}</> : "Unlimited"}, 
                                        Hourly: {data.limitImpressions && JSON.parse(data.limitImpressions).limitImpressionsHourly ? <>{JSON.parse(data.limitImpressions).limitImpressionsHourly}</> : "Unlimited"}
                                    </div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Clicks</div> 
                                    <div className="campaign-input-blk">
                                        Total: {data.limitClicks && JSON.parse(data.limitClicks).limitClicksTotal ? <>{JSON.parse(data.limitClicks).limitClicksTotal}</> : "Unlimited"}, 
                                        Daily: {data.limitClicks && JSON.parse(data.limitClicks).limitClicksDaily ? <>{JSON.parse(data.limitClicks).limitClicksDaily}</> : "Unlimited"}, 
                                        Hourly: {data.limitClicks && JSON.parse(data.limitClicks).limitClicksHourly ? <>{JSON.parse(data.limitClicks).limitClicksHourly}</> : "Unlimited"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="section-list-block">
                            <h3>GEO</h3>
                            <div className="cam-sub-head-labin-block">
                                <h5>Geotargeting</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Countries</div> 
                                    <div className="campaign-input-blk">
                                        {data.geoTargetCountry && JSON.parse(data.geoTargetCountry).length ? JSON.parse(data.geoTargetCountry).map((i, v) => (
                                            <p>{i.label}</p>
                                        )) : ""}
                                    </div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Whole Country</div> 
                                    <div className="campaign-input-blk">{data.geoTargetWholeCountry === "1"? "True" : "False"}</div>
                                </div>
                            </div>
                            { data.geoTargetWholeCountry === "0" && <div className="cam-sub-head-labin-block">
                                <h5>{data.geoTargetZoneType ? _.startCase(data.geoTargetZoneType) : ""}</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Allow list</div> 
                                    <div className="campaign-input-blk">{ data.geoTargetAllowedZone ? <a href={ process.env.REACT_APP_AWS_S3_URL + data.geoTargetAllowedZone } target="_blank" rel="noreferrer">{data.geoTargetAllowedZone }</a> : ""}</div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Available</div> 
                                    <div className="campaign-input-blk">Delhi, Lucknow, Panjab</div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Deny list</div> 
                                    <div className="campaign-input-blk">{ data.geoTargetDeniedZone ? <a href={ process.env.REACT_APP_AWS_S3_URL + data.geoTargetDeniedZone } target="_blank" rel="noreferrer">{data.geoTargetDeniedZone }</a> : ""}</div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Selected</div> 
                                    <div className="campaign-input-blk">Mumbai, Dehradun, Goa</div>
                                </div>
                                { data.geoTargetZoneType && data.geoTargetZoneType !== "cities" && <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Geo precision</div> 
                                    <div className="campaign-input-blk">{data.geoTargetPrecision ? _.upperCase(data.geoTargetPrecision) : ""}</div>
                                </div> }
                            </div> }
                        </div>
                        
                        <div className="section-list-block">
                            <h3>Banners & Landingpage</h3>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Image Banner</div> 
                                <div className="campaign-input-blk"><a href={data.bannerImageType === "image" ? process.env.REACT_APP_AWS_S3_URL + data.bannerImage : data.bannerImage } target="_blank" rel="noreferrer">{data.bannerImage && <img src={data.bannerImageType === "image" ? process.env.REACT_APP_AWS_S3_URL + data.bannerImage : data.bannerImage } alt="pulse" title="Image Banner"/>}</a></div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Video Banner</div> 
                                <div className="campaign-input-blk"><a href={data.bannerVideoType === "video" ? process.env.REACT_APP_AWS_S3_URL + data.bannerVideo : data.bannerVideo } target="_blank" rel="noreferrer">{data.bannerVideoType === "video" ? process.env.REACT_APP_AWS_S3_URL + data.bannerVideo : data.bannerVideo }</a></div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Native Banner</div> 
                                <div className="campaign-input-blk">{ data.bannerNativeIcon && <a href={process.env.REACT_APP_AWS_S3_URL + data.bannerNativeIcon } target="_blank" rel="noreferrer"><img src={process.env.REACT_APP_AWS_S3_URL + data.bannerNativeIcon } alt="ConnectX Activation"  title="Native Banner Icon"/></a>} { data.bannerNativeImage && <a href={process.env.REACT_APP_AWS_S3_URL + data.bannerNativeImage } target="_blank" rel="noreferrer"><img src={process.env.REACT_APP_AWS_S3_URL + data.bannerNativeImage } alt="ConnectX Activation"  title="Native Banner main Image"/></a>}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Title</div> 
                                <div className="campaign-input-blk">{data.bannerTitle ? data.bannerTitle : ""}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Description</div> 
                                <div className="campaign-input-blk">{data.bannerDescription ? data.bannerDescription : ""}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Landing Page/URL</div> 
                                <div className="campaign-input-blk">{ data.bannerLandingPageUrl && <a href={data.bannerLandingPageUrl} target="_blank" rel="noreferrer">{data.bannerLandingPageUrl}</a>}</div>
                            </div>
                            <div className="cam-labin-blk">
                                <div className="campaign-label-blk">Additional Comment</div> 
                                <div className="campaign-input-blk">{data.bannerComments ? data.bannerComments : ""}</div>
                            </div>
                        </div>
                        
                        <div className="section-list-block">
                            <h3>Devices</h3>
                            <div className="cam-sub-head-labin-block">
                                <h5>OS and Device Type</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Smartphone</div> 
                                    <div className="campaign-input-blk">
                                        {data.deviceMobile && JSON.parse(data.deviceMobile).spios ? "iOS, " : ""}
                                        {data.deviceMobile && JSON.parse(data.deviceMobile).spand ? "Android, " : ""}
                                        {data.deviceMobile && JSON.parse(data.deviceMobile).spwnd ? "Windows " : ""}
                                    </div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Tablet</div> 
                                    <div className="campaign-input-blk">
                                        {data.deviceMobile && JSON.parse(data.deviceMobile).mios ? "iOS, " : ""}
                                        {data.deviceMobile && JSON.parse(data.deviceMobile).mand ? "Android, " : ""}
                                        {data.deviceMobile && JSON.parse(data.deviceMobile).mwnd ? "Windows " : ""}
                                    </div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Desktop</div> 
                                    <div className="campaign-input-blk">
                                        {data.deviceDesktop && JSON.parse(data.deviceDesktop).mos ? "Mac OS, " : ""}
                                        {data.deviceDesktop && JSON.parse(data.deviceDesktop).wnd ? "Windows, " : ""}
                                        {data.deviceDesktop && JSON.parse(data.deviceDesktop).lix ? "Linux " : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="cam-sub-head-labin-block">
                                <h5>Language Targeting</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Languages</div> 
                                    <div className="campaign-input-blk">
                                        {data.deviceLanguage && JSON.parse(data.deviceLanguage).length ? JSON.parse(data.deviceLanguage).map((i, v) => (
                                            <p>{i.label}</p>
                                        )) : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="cam-sub-head-labin-block">
                                <h5>Frequency Capping</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Frequency</div> 
                                    <div className="campaign-input-blk">{data.cappingFrequency ? data.cappingFrequency : ""}</div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Period</div> 
                                    <div className="campaign-input-blk">{data.cappingPeriod ? data.cappingPeriod : ""}</div>
                                </div>
                            </div>
                            <div className="cam-sub-head-labin-block">
                                <h5>Traffic Limits</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Traffic Type</div> 
                                    <div className="campaign-input-blk">{data.trafficType ? data.trafficType : ""}</div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Upload and serve on a selected list of device-id’s</div> 
                                    <div className="campaign-input-blk">{ data.deviceIdsURL ? <a href={ process.env.REACT_APP_AWS_S3_URL + data.deviceIdsURL } target="_blank" rel="noreferrer">{data.deviceIdsURL }</a> : ""}</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="section-list-block">
                            <h3>Distribution</h3>
                            <div className="cam-sub-head-labin-block">
                                <h5>Basic information</h5>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Selected DSP's</div> 
                                    <div className="campaign-input-blk">
                                        {data.distType && JSON.parse(data.distType).meadiasmart ? "MediaSmart, " : ""}
                                        {data.distType && JSON.parse(data.distType).smadex ? "Smadex, " : ""}
                                        {data.distType && JSON.parse(data.distType).epom ? "Epom, " : ""}
                                        {data.distType && JSON.parse(data.distType).smartyads ? "SmartyAds" : ""}
                                    </div>
                                </div>
                                <div className="cam-labin-blk">
                                    <div className="campaign-label-blk">Additional Comments</div> 
                                    <div className="campaign-input-blk">{data.distComment ? data.distComment : ""}</div>
                                </div>
                            </div>
                        </div>
                    </div> }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CampaignData;
