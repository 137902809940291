import CampaignData from '../../components/CampaignData';

const CampaignList = () => {
    return (
        <div className="dashboard-content-block">
            <div className="dashbaord-heading">
            Submitted Campaigns
            </div>
            <div className="user-table-block">
                <CampaignData viewType="admin" dataType="submitted"/>
            </div>
        </div>
    );
};

export default CampaignList;