import { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Accordion, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { Context } from "../../../components/Context";
import { CAMPAIGN_MAIN, fetchData } from "../../../components/Service";
import { ActionButton, displayError } from "../../../components/Helper";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";

const Main = (props) => {
    const [Modal1, setModal1] = useState(false);
    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const navigate = useNavigate()
    const selectedScheduleDiv = props && props.campaignData && props.campaignData.schedule ? JSON.parse(props?.campaignData?.schedule) : ""
    const [endDate, setEndDate] = useState(props && props.campaignData && props.campaignData.endDate ? new Date(props.campaignData.endDate): new Date());
    const [startDate, setStartDate] = useState(new Date());

    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);

    const [scheduleDivs, setScheduleDivs] = useState([{
        "weekId": Date.now(),
        "weekType":"All",
        "weekTypeFH": "00",
        "weekTypeFM": "00",
        "weekTypeTH": "00",
        "weekTypeTM": "14"
    }]);

    const setContinue = () => {
        handleClose1()
        props.makeTabAcive("budget")
    }

    // Handler function to add a new div with a unique ID
    const addScheduleDiv = () => {
        const newDiv = {
            weekId: Date.now(),
        };
        setScheduleDivs([...scheduleDivs, newDiv]);
    };

    // Handler function to delete a div by its ID
    const deleteDiv = (id) => {
        setScheduleDivs(scheduleDivs.filter(div => div.weekId !== id));
    };

    const saveData = (e) => {
        let formData = new FormData(document.getElementById("campaign-main"));
        
        if(formData.get("name") === "" || formData.get("domain") === "" || formData.get("landingPageUrl") === "" || selectedOption === "") {
            handleShow1();
        }

        const structuredData = {};

        formData.forEach((value, key) => {
            console.log(value)
            const match = key.match(/schedule\[(\d+)\]\[(\w+)\]/);
            if (match) {
                const timestamp = match[1];
                const field = match[2];

                if (!structuredData[timestamp]) {
                    structuredData[timestamp] = {};
                }

                structuredData[timestamp][field] = value;
            }
        });

        
        const nestedArray = Object.keys(structuredData).map(key => structuredData[key]);

        let data = {
            userId: context && context.id ? context.id : 0,
            campaignId: context && context.campaignId ? context.campaignId : "",
            name: formData.get("name"),
            campaign: formData.get("campaign"),
            startDate: formData.get("startDate"),
            endDate: formData.get("endDate"),
            schedule: JSON.stringify(nestedArray),
            representativeCategory: selectedOption,
            domain: formData.get("domain"),
            bundle: formData.get("bundle"),
            landingPageUrl: formData.get("landingPageUrl"),
        };

        setTinyloader(true);

        fetchData(CAMPAIGN_MAIN, "POST", data, true, false, (res) => {
            setTinyloader(false);
            displayError(res.errors);
            if (res.status === "success") {
                setContext(prevValue => ({
                    ...prevValue,
                    campaignId: res?.records?.id,
                    tabName: res?.records?.formNamePath
                }))
                localStorage.setItem('__t6t_campaign_id', res?.records?.id)
                props.makeTabAcive("", res?.records?.id)
            }
        });
    };

    useEffect(() => {
        if(props && props.campaignData) {
            setEndDate(props.campaignData.endDate ? props.campaignData.endDate : new Date());
            setStartDate(props.campaignData.startDate ? props.campaignData.startDate : new Date());
            setScheduleDivs(props.campaignData.schedule ? selectedScheduleDiv : [{
                "weekId": Date.now(),
                "weekType":"All",
                "weekTypeFH": "00",
                "weekTypeFM": "00",
                "weekTypeTH": "00",
                "weekTypeTM": "14"
            }])

            setSelectedOption(props.campaignData.representativeCategory ? JSON.parse(props.campaignData.representativeCategory) : "")
            handleChange(props.campaignData.representativeCategory ? JSON.parse(props.campaignData.representativeCategory) : "")
        }
    }, [props])

    const allOptions = [
        {
        "value": "IAB1",
        "label": "IAB1 - Arts & Entertainment",
        "children": [
            { "value": "IAB1-1", "label": "IAB1-1 - Books & Literature" },
            { "value": "IAB1-2", "label": "IAB1-2 - Celebrity Fan/Gossip" },
            { "value": "IAB1-3", "label": "IAB1-3 - Fine Art" },
            { "value": "IAB1-4", "label": "IAB1-4 - Humor" },
            { "value": "IAB1-5", "label": "IAB1-5 - Movies" },
            { "value": "IAB1-6", "label": "IAB1-6 - Music" },
            { "value": "IAB1-7", "label": "IAB1-7 - Television" }
        ]
        },
        {
        "value": "IAB2",
        "label": "IAB2 - Automotive",
        "children": [
            { "value": "IAB2-1", "label": "IAB2-1 - Auto Parts" },
            { "value": "IAB2-2", "label": "IAB2-2 - Auto Repair" },
            { "value": "IAB2-3", "label": "IAB2-3 - Buying/Selling Cars" },
            { "value": "IAB2-4", "label": "IAB2-4 - Car Culture" },
            { "value": "IAB2-5", "label": "IAB2-5 - Certified Pre-Owned" },
            { "value": "IAB2-6", "label": "IAB2-6 - Convertible" },
            { "value": "IAB2-7", "label": "IAB2-7 - Coupe" },
            { "value": "IAB2-8", "label": "IAB2-8 - Crossover" },
            { "value": "IAB2-9", "label": "IAB2-9 - Diesel" },
            { "value": "IAB2-10", "label": "IAB2-10 - Electric Vehicle" },
            { "value": "IAB2-11", "label": "IAB2-11 - Hatchback" },
            { "value": "IAB2-12", "label": "IAB2-12 - Hybrid" },
            { "value": "IAB2-13", "label": "IAB2-13 - Luxury" },
            { "value": "IAB2-14", "label": "IAB2-14 - MiniVan" },
            { "value": "IAB2-15", "label": "IAB2-15 - Motorcycles" },
            { "value": "IAB2-16", "label": "IAB2-16 - Off-Road Vehicles" },
            { "value": "IAB2-17", "label": "IAB2-17 - Performance Vehicles" },
            { "value": "IAB2-18", "label": "IAB2-18 - Pickup" },
            { "value": "IAB2-19", "label": "IAB2-19 - Road-Side Assistance" },
            { "value": "IAB2-20", "label": "IAB2-20 - Sedan" },
            { "value": "IAB2-21", "label": "IAB2-21 - Trucks & Accessories" },
            { "value": "IAB2-22", "label": "IAB2-22 - Vintage Cars" },
            { "value": "IAB2-23", "label": "IAB2-23 - Wagon" }
        ]
        },
        {
        "value": "IAB3",
        "label": "IAB3 - Business",
        "children": [
            { "value": "IAB3-1", "label": "IAB3-1 - Advertising" },
            { "value": "IAB3-2", "label": "IAB3-2 - Agriculture" },
            { "value": "IAB3-3", "label": "IAB3-3 - Biotech/Biomedical" },
            { "value": "IAB3-4", "label": "IAB3-4 - Business Software" },
            { "value": "IAB3-5", "label": "IAB3-5 - Construction" },
            { "value": "IAB3-6", "label": "IAB3-6 - Forestry" },
            { "value": "IAB3-7", "label": "IAB3-7 - Government" },
            { "value": "IAB3-8", "label": "IAB3-8 - Green Solutions" },
            { "value": "IAB3-9", "label": "IAB3-9 - Human Resources" },
            { "value": "IAB3-10", "label": "IAB3-10 - Logistics" },
            { "value": "IAB3-11", "label": "IAB3-11 - Marketing" },
            { "value": "IAB3-12", "label": "IAB3-12 - Metals" }
        ]
        },
        {
        "value": "IAB4",
        "label": "IAB4 - Careers",
        "children": [
            { "value": "IAB4-1", "label": "IAB4-1 - Career Planning" },
            { "value": "IAB4-2", "label": "IAB4-2 - College" },
            { "value": "IAB4-3", "label": "IAB4-3 - Financial Aid" },
            { "value": "IAB4-4", "label": "IAB4-4 - Job Fairs" },
            { "value": "IAB4-5", "label": "IAB4-5 - Job Search" },
            { "value": "IAB4-6", "label": "IAB4-6 - Resume Writing/Advice" },
            { "value": "IAB4-7", "label": "IAB4-7 - Nursing" },
            { "value": "IAB4-8", "label": "IAB4-8 - Scholarships" },
            { "value": "IAB4-9", "label": "IAB4-9 - Telecommuting" },
            { "value": "IAB4-10", "label": "IAB4-10 - U.S. Military" },
            { "value": "IAB4-11", "label": "IAB4-11 - Career Advice" }
        ]
        },
        {
        "value": "IAB5",
        "label": "IAB5 - Education",
        "children": [
            { "value": "IAB5-1", "label": "IAB5-1 - 7-12 Education" },
            { "value": "IAB5-2", "label": "IAB5-2 - Adult Education" },
            { "value": "IAB5-3", "label": "IAB5-3 - Art History" },
            { "value": "IAB5-4", "label": "IAB5-4 - College Administration" },
            { "value": "IAB5-5", "label": "IAB5-5 - College Life" },
            { "value": "IAB5-6", "label": "IAB5-6 - Distance Learning" },
            { "value": "IAB5-7", "label": "IAB5-7 - English as a 2nd Language" },
            { "value": "IAB5-8", "label": "IAB5-8 - Language Learning" },
            { "value": "IAB5-9", "label": "IAB5-9 - Graduate School" },
            { "value": "IAB5-10", "label": "IAB5-10 - Homeschooling" },
            { "value": "IAB5-11", "label": "IAB5-11 - Homework/Study Tips" },
            { "value": "IAB5-12", "label": "IAB5-12 - K-6 Educators" },
            { "value": "IAB5-13", "label": "IAB5-13 - Private School" },
            { "value": "IAB5-14", "label": "IAB5-14 - Special Education" },
            { "value": "IAB5-15", "label": "IAB5-15 - Studying Business" }
        ]
        },
        {
        "value": "IAB6",
        "label": "IAB6 - Family & Parenting",
        "children": [
            { "value": "IAB6-1", "label": "IAB6-1 - Adoption" },
            { "value": "IAB6-2", "label": "IAB6-2 - Babies & Toddlers" },
            { "value": "IAB6-3", "label": "IAB6-3 - Daycare/Pre School" },
            { "value": "IAB6-4", "label": "IAB6-4 - Family Internet" },
            { "value": "IAB6-5", "label": "IAB6-5 - Parenting - K-6 Kids" },
            { "value": "IAB6-6", "label": "IAB6-6 - Parenting teens" },
            { "value": "IAB6-7", "label": "IAB6-7 - Pregnancy" },
            { "value": "IAB6-8", "label": "IAB6-8 - Special Needs Kids" },
            { "value": "IAB6-9", "label": "IAB6-9 - Eldercare" }
        ]
        },
        {
        "value": "IAB7",
        "label": "IAB7 - Health & Fitness",
        "children": [
            { "value": "IAB7-1", "label": "IAB7-1 - Exercise" },
            { "value": "IAB7-2", "label": "IAB7-2 - A.D.D." },
            { "value": "IAB7-3", "label": "IAB7-3 - AIDS/HIV" },
            { "value": "IAB7-4", "label": "IAB7-4 - Allergies" },
            { "value": "IAB7-5", "label": "IAB7-5 - Alternative Medicine" },
            { "value": "IAB7-6", "label": "IAB7-6 - Arthritis" },
            { "value": "IAB7-7", "label": "IAB7-7 - Asthma" },
            { "value": "IAB7-8", "label": "IAB7-8 - Autism/PDD" },
            { "value": "IAB7-9", "label": "IAB7-9 - Bipolar Disorder" },
            { "value": "IAB7-10", "label": "IAB7-10 - Brain Tumor" },
            { "value": "IAB7-11", "label": "IAB7-11 - Cancer" },
            { "value": "IAB7-12", "label": "IAB7-12 - Cholesterol" },
            { "value": "IAB7-13", "label": "IAB7-13 - Chronic Fatigue Syndrome" },
            { "value": "IAB7-14", "label": "IAB7-14 - Chronic Pain" },
            { "value": "IAB7-15", "label": "IAB7-15 - Cold & Flu" },
            { "value": "IAB7-16", "label": "IAB7-16 - Deafness" },
            { "value": "IAB7-17", "label": "IAB7-17 - Dental Care" },
            { "value": "IAB7-18", "label": "IAB7-18 - Depression" },
            { "value": "IAB7-19", "label": "IAB7-19 - Dermatology" },
            { "value": "IAB7-20", "label": "IAB7-20 - Diabetes" },
            { "value": "IAB7-21", "label": "IAB7-21 - Epilepsy" },
            { "value": "IAB7-22", "label": "IAB7-22 - GERD/Acid Reflux" },
            { "value": "IAB7-23", "label": "IAB7-23 - Headaches/Migraines" },
            { "value": "IAB7-24", "label": "IAB7-24 - Heart Disease" },
            { "value": "IAB7-25", "label": "IAB7-25 - Herbs for Health" },
            { "value": "IAB7-26", "label": "IAB7-26 - Holistic Healing" },
            { "value": "IAB7-27", "label": "IAB7-27 - IBS/Crohn’s Disease" },
            { "value": "IAB7-28", "label": "IAB7-28 - Incest/Abuse Support" },
            { "value": "IAB7-29", "label": "IAB7-29 - Incontinence" },
            { "value": "IAB7-30", "label": "IAB7-30 - Infertility" },
            { "value": "IAB7-31", "label": "IAB7-31 - Men’s Health" },
            { "value": "IAB7-32", "label": "IAB7-32 - Nutrition" },
            { "value": "IAB7-33", "label": "IAB7-33 - Orthopedics" },
            { "value": "IAB7-34", "label": "IAB7-34 - Panic/Anxiety Disorders" },
            { "value": "IAB7-35", "label": "IAB7-35 - Pediatrics" },
            { "value": "IAB7-36", "label": "IAB7-36 - Physical Therapy" },
            { "value": "IAB7-37", "label": "IAB7-37 - Psychology/Psychiatry" },
            { "value": "IAB7-38", "label": "IAB7-38 - Senior Health" },
            { "value": "IAB7-39", "label": "IAB7-39 - Sexuality" },
            { "value": "IAB7-40", "label": "IAB7-40 - Sleep Disorders" },
            { "value": "IAB7-41", "label": "IAB7-41 - Smoking Cessation" },
            { "value": "IAB7-42", "label": "IAB7-42 - Substance Abuse" },
            { "value": "IAB7-43", "label": "IAB7-43 - Thyroid Disease" },
            { "value": "IAB7-44", "label": "IAB7-44 - Weight Loss" },
            { "value": "IAB7-45", "label": "IAB7-45 - Women’s Health" }
        ]
        },
        {
        "value": "IAB8",
        "label": "IAB8 - Food & Drink",
        "children": [
            { "value": "IAB8-1", "label": "IAB8-1 - American Cuisine" },
            { "value": "IAB8-2", "label": "IAB8-2 - Barbecues & Grilling" },
            { "value": "IAB8-3", "label": "IAB8-3 - Cajun/Creole" },
            { "value": "IAB8-4", "label": "IAB8-4 - Chinese Cuisine" },
            { "value": "IAB8-5", "label": "IAB8-5 - Cocktails/Beer" },
            { "value": "IAB8-6", "label": "IAB8-6 - Coffee/Tea" },
            { "value": "IAB8-7", "label": "IAB8-7 - Cuisine-Specific" },
            { "value": "IAB8-8", "label": "IAB8-8 - Desserts & Baking" },
            { "value": "IAB8-9", "label": "IAB8-9 - Dining Out" },
            { "value": "IAB8-10", "label": "IAB8-10 - Food Allergies" },
            { "value": "IAB8-11", "label": "IAB8-11 - French Cuisine" },
            { "value": "IAB8-12", "label": "IAB8-12 - Health/Lowfat Cooking" },
            { "value": "IAB8-13", "label": "IAB8-13 - Italian Cuisine" },
            { "value": "IAB8-14", "label": "IAB8-14 - Japanese Cuisine" },
            { "value": "IAB8-15", "label": "IAB8-15 - Mexican Cuisine" },
            { "value": "IAB8-16", "label": "IAB8-16 - Vegan" },
            { "value": "IAB8-17", "label": "IAB8-17 - Vegetarian" },
            { "value": "IAB8-18", "label": "IAB8-18 - Wine" }
        ]
        },
        {
        "value": "IAB9",
        "label": "IAB9 - Hobbies & Interests",
        "children": [
            { "value": "IAB9-1", "label": "IAB9-1 - Art/Technology" },
            { "value": "IAB9-2", "label": "IAB9-2 - Arts & Crafts" },
            { "value": "IAB9-3", "label": "IAB9-3 - Beadwork" },
            { "value": "IAB9-4", "label": "IAB9-4 - Birdwatching" },
            { "value": "IAB9-5", "label": "IAB9-5 - Board Games/Puzzles" },
            { "value": "IAB9-6", "label": "IAB9-6 - Candle & Soap Making" },
            { "value": "IAB9-7", "label": "IAB9-7 - Card Games" },
            { "value": "IAB9-8", "label": "IAB9-8 - Chess" },
            { "value": "IAB9-9", "label": "IAB9-9 - Cigars" },
            { "value": "IAB9-10", "label": "IAB9-10 - Collecting" },
            { "value": "IAB9-11", "label": "IAB9-11 - Comic Books" },
            { "value": "IAB9-12", "label": "IAB9-12 - Drawing/Sketching" },
            { "value": "IAB9-13", "label": "IAB9-13 - Freelance Writing" },
            { "value": "IAB9-14", "label": "IAB9-14 - Genealogy" },
            { "value": "IAB9-15", "label": "IAB9-15 - Getting Published" },
            { "value": "IAB9-16", "label": "IAB9-16 - Guitar" },
            { "value": "IAB9-17", "label": "IAB9-17 - Home Recording" },
            { "value": "IAB9-18", "label": "IAB9-18 - Investors & Patents" },
            { "value": "IAB9-19", "label": "IAB9-19 - Jewelry Making" },
            { "value": "IAB9-20", "label": "IAB9-20 - Magic & Illusion" },
            { "value": "IAB9-21", "label": "IAB9-21 - Needlework" },
            { "value": "IAB9-22", "label": "IAB9-22 - Painting" },
            { "value": "IAB9-23", "label": "IAB9-23 - Photography" },
            { "value": "IAB9-24", "label": "IAB9-24 - Radio" },
            { "value": "IAB9-25", "label": "IAB9-25 - Roleplaying Games" },
            { "value": "IAB9-26", "label": "IAB9-26 - Sci-Fi & Fantasy" },
            { "value": "IAB9-27", "label": "IAB9-27 - Scrapbooking" },
            { "value": "IAB9-28", "label": "IAB9-28 - Screenwriting" },
            { "value": "IAB9-29", "label": "IAB9-29 - Stamps & Coins" },
            { "value": "IAB9-30", "label": "IAB9-30 - Video & Computer Games" },
            { "value": "IAB9-31", "label": "IAB9-31 - Woodworking" }
        ]
        },
        {
        "value": "IAB10",
        "label": "IAB10 - Home & Garden",
        "children": [
            { "value": "IAB10-1", "label": "IAB10-1 - Appliances" },
            { "value": "IAB10-2", "label": "IAB10-2 - Entertaining" },
            { "value": "IAB10-3", "label": "IAB10-3 - Environmental Safety" },
            { "value": "IAB10-4", "label": "IAB10-4 - Gardening" },
            { "value": "IAB10-5", "label": "IAB10-5 - Home Repair" },
            { "value": "IAB10-6", "label": "IAB10-6 - Home Theater" },
            { "value": "IAB10-7", "label": "IAB10-7 - Interior Decorating" },
            { "value": "IAB10-8", "label": "IAB10-8 - Landscaping" },
            { "value": "IAB10-9", "label": "IAB10-9 - Remodeling & Construction" }
        ]
        },
        {
        "value": "IAB11",
        "label": "IAB11 - Law, Gov’t & Politics",
        "children": [
            { "value": "IAB11-1", "label": "IAB11-1 - Immigration" },
            { "value": "IAB11-2", "label": "IAB11-2 - Legal Issues" },
            { "value": "IAB11-3", "label": "IAB11-3 - U.S. Government Resources" },
            { "value": "IAB11-4", "label": "IAB11-4 - Politics" },
            { "value": "IAB11-5", "label": "IAB11-5 - Commentary" }
        ]
        },
        {
        "value": "IAB12",
        "label": "IAB12 - News",
        "children": [
            { "value": "IAB12-1", "label": "IAB12-1 - International News" },
            { "value": "IAB12-2", "label": "IAB12-2 - National News" },
            { "value": "IAB12-3", "label": "IAB12-3 - Local News" }
        ]
        },
        {
        "value": "IAB13",
        "label": "IAB13 - Personal Finance",
        "children": [
            { "value": "IAB13-1", "label": "IAB13-1 - Beginning Investing" },
            { "value": "IAB13-2", "label": "IAB13-2 - Credit/Debt & Loans" },
            { "value": "IAB13-3", "label": "IAB13-3 - Financial News" },
            { "value": "IAB13-4", "label": "IAB13-4 - Financial Planning" },
            { "value": "IAB13-5", "label": "IAB13-5 - Hedge Fund" },
            { "value": "IAB13-6", "label": "IAB13-6 - Insurance" },
            { "value": "IAB13-7", "label": "IAB13-7 - Investing" },
            { "value": "IAB13-8", "label": "IAB13-8 - Mutual Funds" },
            { "value": "IAB13-9", "label": "IAB13-9 - Options" },
            { "value": "IAB13-10", "label": "IAB13-10 - Retirement Planning" },
            { "value": "IAB13-11", "label": "IAB13-11 - Stocks" },
            { "value": "IAB13-12", "label": "IAB13-12 - Tax Planning" }
        ]
        },
        {
        "value": "IAB14",
        "label": "IAB14 - Society",
        "children": [
            { "value": "IAB14-1", "label": "IAB14-1 - Dating" },
            { "value": "IAB14-2", "label": "IAB14-2 - Divorce Support" },
            { "value": "IAB14-3", "label": "IAB14-3 - Gay Life" },
            { "value": "IAB14-4", "label": "IAB14-4 - Marriage" },
            { "value": "IAB14-5", "label": "IAB14-5 - Senior Living" },
            { "value": "IAB14-6", "label": "IAB14-6 - Teens" },
            { "value": "IAB14-7", "label": "IAB14-7 - Weddings" },
            { "value": "IAB14-8", "label": "IAB14-8 - Ethnic Specific" }
        ]
        },
        {
        "value": "IAB15",
        "label": "IAB15 - Science",
        "children": [
            { "value": "IAB15-1", "label": "IAB15-1 - Astrology" },
            { "value": "IAB15-2", "label": "IAB15-2 - Biology" },
            { "value": "IAB15-3", "label": "IAB15-3 - Chemistry" },
            { "value": "IAB15-4", "label": "IAB15-4 - Geology" },
            { "value": "IAB15-5", "label": "IAB15-5 - Paranormal Phenomena" },
            { "value": "IAB15-6", "label": "IAB15-6 - Physics" },
            { "value": "IAB15-7", "label": "IAB15-7 - Space/Astronomy" },
            { "value": "IAB15-8", "label": "IAB15-8 - Geography" },
            { "value": "IAB15-9", "label": "IAB15-9 - Botany" },
            { "value": "IAB15-10", "label": "IAB15-10 - Weather" }
        ]
        },
        {
        "value": "IAB16",
        "label": "IAB16 - Pets",
        "children": [
            { "value": "IAB16-1", "label": "IAB16-1 - Aquariums" },
            { "value": "IAB16-2", "label": "IAB16-2 - Birds" },
            { "value": "IAB16-3", "label": "IAB16-3 - Cats" },
            { "value": "IAB16-4", "label": "IAB16-4 - Dogs" },
            { "value": "IAB16-5", "label": "IAB16-5 - Large Animals" },
            { "value": "IAB16-6", "label": "IAB16-6 - Reptiles" },
            { "value": "IAB16-7", "label": "IAB16-7 - Veterinary Medicine" }
        ]
        },
        {
        "value": "IAB17",
        "label": "IAB17 - Sports",
        "children": [
            { "value": "IAB17-1", "label": "IAB17-1 - Auto Racing" },
            { "value": "IAB17-2", "label": "IAB17-2 - Baseball" },
            { "value": "IAB17-3", "label": "IAB17-3 - Bicycling" },
            { "value": "IAB17-4", "label": "IAB17-4 - Bodybuilding" },
            { "value": "IAB17-5", "label": "IAB17-5 - Boxing" },
            { "value": "IAB17-6", "label": "IAB17-6 - Canoeing/Kayaking" },
            { "value": "IAB17-7", "label": "IAB17-7 - Cheerleading" },
            { "value": "IAB17-8", "label": "IAB17-8 - Climbing" },
            { "value": "IAB17-9", "label": "IAB17-9 - Cricket" },
            { "value": "IAB17-10", "label": "IAB17-10 - Figure Skating" },
            { "value": "IAB17-11", "label": "IAB17-11 - Fly Fishing" },
            { "value": "IAB17-12", "label": "IAB17-12 - Football" },
            { "value": "IAB17-13", "label": "IAB17-13 - Freshwater Fishing" },
            { "value": "IAB17-14", "label": "IAB17-14 - Game & Fish" },
            { "value": "IAB17-15", "label": "IAB17-15 - Golf" },
            { "value": "IAB17-16", "label": "IAB17-16 - Horse Racing" },
            { "value": "IAB17-17", "label": "IAB17-17 - Horses" },
            { "value": "IAB17-18", "label": "IAB17-18 - Hunting/Shooting" },
            { "value": "IAB17-19", "label": "IAB17-19 - Inline Skating" },
            { "value": "IAB17-20", "label": "IAB17-20 - Martial Arts" },
            { "value": "IAB17-21", "label": "IAB17-21 - Mountain Biking" },
            { "value": "IAB17-22", "label": "IAB17-22 - NASCAR Racing" },
            { "value": "IAB17-23", "label": "IAB17-23 - Olympics" },
            { "value": "IAB17-24", "label": "IAB17-24 - Paintball" },
            { "value": "IAB17-25", "label": "IAB17-25 - Power & Motorcycles" },
            { "value": "IAB17-26", "label": "IAB17-26 - Pro Basketball" },
            { "value": "IAB17-27", "label": "IAB17-27 - Pro Ice Hockey" },
            { "value": "IAB17-28", "label": "IAB17-28 - Rodeo" },
            { "value": "IAB17-29", "label": "IAB17-29 - Rugby" },
            { "value": "IAB17-30", "label": "IAB17-30 - Running/Jogging" },
            { "value": "IAB17-31", "label": "IAB17-31 - Sailing" },
            { "value": "IAB17-32", "label": "IAB17-32 - Saltwater Fishing" },
            { "value": "IAB17-33", "label": "IAB17-33 - Scuba Diving" },
            { "value": "IAB17-34", "label": "IAB17-34 - Skateboarding" },
            { "value": "IAB17-35", "label": "IAB17-35 - Skiing" },
            { "value": "IAB17-36", "label": "IAB17-36 - Snowboarding" },
            { "value": "IAB17-37", "label": "IAB17-37 - Surfing/Bodyboarding" },
            { "value": "IAB17-38", "label": "IAB17-38 - Swimming" },
            { "value": "IAB17-39", "label": "IAB17-39 - Table Tennis/Ping-Pong" },
            { "value": "IAB17-40", "label": "IAB17-40 - Tennis" },
            { "value": "IAB17-41", "label": "IAB17-41 - Volleyball" },
            { "value": "IAB17-42", "label": "IAB17-42 - Walking" },
            { "value": "IAB17-43", "label": "IAB17-43 - Waterski/Wakeboard" },
            { "value": "IAB17-44", "label": "IAB17-44 - World Soccer" }
        ]
        },
        {
        "value": "IAB18",
        "label": "IAB18 - Style & Fashion",
        "children": [
            { "value": "IAB18-1", "label": "IAB18-1 - Beauty" },
            { "value": "IAB18-2", "label": "IAB18-2 - Body Art" },
            { "value": "IAB18-3", "label": "IAB18-3 - Fashion" },
            { "value": "IAB18-4", "label": "IAB18-4 - Jewelry" },
            { "value": "IAB18-5", "label": "IAB18-5 - Clothing" },
            { "value": "IAB18-6", "label": "IAB18-6 - Accessories" }
        ]
        },
        {
        "value": "IAB19",
        "label": "IAB19 - Technology & Computing",
        "children": [
            { "value": "IAB19-1", "label": "IAB19-1 - 3-D Graphics" },
            { "value": "IAB19-2", "label": "IAB19-2 - Animation" },
            { "value": "IAB19-3", "label": "IAB19-3 - Antivirus Software" },
            { "value": "IAB19-4", "label": "IAB19-4 - C/C++" },
            { "value": "IAB19-5", "label": "IAB19-5 - Cameras & Camcorders" },
            { "value": "IAB19-6", "label": "IAB19-6 - Cell Phones" },
            { "value": "IAB19-7", "label": "IAB19-7 - Computer Certification" },
            { "value": "IAB19-8", "label": "IAB19-8 - Computer Networking" },
            { "value": "IAB19-9", "label": "IAB19-9 - Computer Peripherals" },
            { "value": "IAB19-10", "label": "IAB19-10 - Computer Reviews" },
            { "value": "IAB19-11", "label": "IAB19-11 - Data Centers" },
            { "value": "IAB19-12", "label": "IAB19-12 - Databases" },
            { "value": "IAB19-13", "label": "IAB19-13 - Desktop Publishing" },
            { "value": "IAB19-14", "label": "IAB19-14 - Desktop Video" },
            { "value": "IAB19-15", "label": "IAB19-15 - Email" },
            { "value": "IAB19-16", "label": "IAB19-16 - Graphics Software" },
            { "value": "IAB19-17", "label": "IAB19-17 - Home Video/DVD" },
            { "value": "IAB19-18", "label": "IAB19-18 - Internet Technology" },
            { "value": "IAB19-19", "label": "IAB19-19 - Java" },
            { "value": "IAB19-20", "label": "IAB19-20 - JavaScript" },
            { "value": "IAB19-21", "label": "IAB19-21 - Mac Support" },
            { "value": "IAB19-22", "label": "IAB19-22 - MP3/MIDI" },
            { "value": "IAB19-23", "label": "IAB19-23 - Net Conferencing" },
            { "value": "IAB19-24", "label": "IAB19-24 - Net for Beginners" },
            { "value": "IAB19-25", "label": "IAB19-25 - Network Security" },
            { "value": "IAB19-26", "label": "IAB19-26 - Palmtops/PDAs" },
            { "value": "IAB19-27", "label": "IAB19-27 - PC Support" },
            { "value": "IAB19-28", "label": "IAB19-28 - Portable" },
            { "value": "IAB19-29", "label": "IAB19-29 - Entertainment" },
            { "value": "IAB19-30", "label": "IAB19-30 - Shareware/Freeware" },
            { "value": "IAB19-31", "label": "IAB19-31 - Unix" },
            { "value": "IAB19-32", "label": "IAB19-32 - Visual Basic" },
            { "value": "IAB19-33", "label": "IAB19-33 - Web Clip Art" },
            { "value": "IAB19-34", "label": "IAB19-34 - Web Design/HTML" },
            { "value": "IAB19-35", "label": "IAB19-35 - Web Search" },
            { "value": "IAB19-36", "label": "IAB19-36 - Windows" }
        ]
        },
        {
        "value": "IAB20",
        "label": "IAB20 - Travel",
        "children": [
            { "value": "IAB20-1", "label": "IAB20-1 - Adventure Travel" },
            { "value": "IAB20-2", "label": "IAB20-2 - Africa" },
            { "value": "IAB20-3", "label": "IAB20-3 - Air Travel" },
            { "value": "IAB20-4", "label": "IAB20-4 - Australia & New Zealand" },
            { "value": "IAB20-5", "label": "IAB20-5 - Bed & Breakfasts" },
            { "value": "IAB20-6", "label": "IAB20-6 - Budget Travel" },
            { "value": "IAB20-7", "label": "IAB20-7 - Business Travel" },
            { "value": "IAB20-8", "label": "IAB20-8 - By US Locale" },
            { "value": "IAB20-9", "label": "IAB20-9 - Camping" },
            { "value": "IAB20-10", "label": "IAB20-10 - Canada" },
            { "value": "IAB20-11", "label": "IAB20-11 - Caribbean" },
            { "value": "IAB20-12", "label": "IAB20-12 - Cruises" },
            { "value": "IAB20-13", "label": "IAB20-13 - Eastern Europe" },
            { "value": "IAB20-14", "label": "IAB20-14 - Europe" },
            { "value": "IAB20-15", "label": "IAB20-15 - France" },
            { "value": "IAB20-16", "label": "IAB20-16 - Greece" },
            { "value": "IAB20-17", "label": "IAB20-17 - Honeymoons/Getaways" },
            { "value": "IAB20-18", "label": "IAB20-18 - Hotels" },
            { "value": "IAB20-19", "label": "IAB20-19 - Italy" },
            { "value": "IAB20-20", "label": "IAB20-20 - Japan" },
            { "value": "IAB20-21", "label": "IAB20-21 - Mexico & Central America" },
            { "value": "IAB20-22", "label": "IAB20-22 - National Parks" },
            { "value": "IAB20-23", "label": "IAB20-23 - South America" },
            { "value": "IAB20-24", "label": "IAB20-24 - Spas" },
            { "value": "IAB20-25", "label": "IAB20-25 - Theme Parks" },
            { "value": "IAB20-26", "label": "IAB20-26 - Traveling with Kids" },
            { "value": "IAB20-27", "label": "IAB20-27 - United Kingdom" }
        ]
        },
        {
        "value": "IAB21",
        "label": "IAB21 - Real Estate",
        "children": [
            { "value": "IAB21-1", "label": "IAB21-1 - Apartments" },
            { "value": "IAB21-2", "label": "IAB21-2 - Architects" },
            { "value": "IAB21-3", "label": "IAB21-3 - Buying/Selling Homes" }
        ]
        },
        {
        "value": "IAB22",
        "label": "IAB22 - Shopping",
        "children": [
            { "value": "IAB22-1", "label": "IAB22-1 - Contests & Freebies" },
            { "value": "IAB22-2", "label": "IAB22-2 - Couponing" },
            { "value": "IAB22-3", "label": "IAB22-3 - Comparison" },
            { "value": "IAB22-4", "label": "IAB22-4 - Engines" }
        ]
        },
        {
        "value": "IAB23",
        "label": "IAB23 - Religion & Spirituality",
        "children": [
            { "value": "IAB23-1", "label": "IAB23-1 - Alternative Religions" },
            { "value": "IAB23-2", "label": "IAB23-2 - Atheism/Agnosticism" },
            { "value": "IAB23-3", "label": "IAB23-3 - Buddhism" },
            { "value": "IAB23-4", "label": "IAB23-4 - Catholicism" },
            { "value": "IAB23-5", "label": "IAB23-5 - Christianity" },
            { "value": "IAB23-6", "label": "IAB23-6 - Hinduism" },
            { "value": "IAB23-7", "label": "IAB23-7 - Islam" },
            { "value": "IAB23-8", "label": "IAB23-8 - Judaism" },
            { "value": "IAB23-9", "label": "IAB23-9 - Latter-Day Saints" },
            { "value": "IAB23-10", "label": "IAB23-10 - Pagan/Wiccan" }
        ]
        },
        {
        "value": "IAB24",
        "label": "IAB24 - Uncategorized",
        "children": []
        },
        {
        "value": "IAB25",
        "label": "IAB25 - Non-Standard Content",
        "children": [
            { "value": "IAB25-1", "label": "IAB25-1 - Unmoderated UGC" },
            { "value": "IAB25-2", "label": "IAB25-2 - Extreme Graphic/Explicit Violence" },
            { "value": "IAB25-3", "label": "IAB25-3 - Pornography" },
            { "value": "IAB25-4", "label": "IAB25-4 - Profane Content" },
            { "value": "IAB25-5", "label": "IAB25-5 - Hate Content" },
            { "value": "IAB25-6", "label": "IAB25-6 - Under Construction" },
            { "value": "IAB25-7", "label": "IAB25-7 - Incentivized" }
        ]
        },
        {
        "value": "IAB26",
        "label": "IAB26 - Illegal Content",
        "children": [
            { "value": "IAB26-1", "label": "IAB26-1 - Illegal Content" },
            { "value": "IAB26-2", "label": "IAB26-2 - Warez" },
            { "value": "IAB26-3", "label": "IAB26-3 - Spyware/Malware" },
            { "value": "IAB26-4", "label": "IAB26-4 - Copyright Infringement" }
        ]
        }      
    ];
    
    const flattenOptions = (options) => {
        let flattened = [];
        options.forEach(parent => {
        flattened.push({ label: parent.label, value: parent.value });
        parent.children.forEach(child => {
            flattened.push({ label: child.label, value: child.value });
        });
        });
        return flattened;
    };

    const findParentForChild = (options, childValue) => {
        return options.find(parent => parent.children.some(child => child.value === childValue));
    };

    const handleSelection = (options, selectedValue) => {
        const selectedParent = options.find(parent => parent.value === selectedValue);
        const selectedChildParent = findParentForChild(options, selectedValue);

        if (selectedParent) {
        return flattenOptions(options).filter(option => 
            option.value !== selectedParent.value &&
            !selectedParent.children.some(child => child.value === option.value)
        );
        } else if (selectedChildParent) {
        return flattenOptions(options).filter(option => option.value !== selectedValue);
        } else {
        return flattenOptions(options);
        }
    };
    
    const [availableOptions, setAvailableOptions] = useState(flattenOptions(allOptions));
    const [selectedOption, setSelectedOption] = useState("");
    
    const handleChange = selectedOption => {
        if (selectedOption) {
            setSelectedOption(selectedOption);
            const selectedValue = selectedOption.value;
        
            const updatedOptions = handleSelection(allOptions, selectedValue);
        
            setAvailableOptions(updatedOptions);
        }
    };

    return (
        <>
            <div className="tab-inner-content">
                <Form id="campaign-main">
                    <h4 className="form-heading">Basic information</h4>
                    <Row>
                        <Col md={6}>
                            <Form.Group id="form-group-name" className="form-group">
                                <Form.Label>
                                    Name <span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    defaultValue={props?.campaignData?.name}
                                />
                                <span
                                    id="form-error-name"
                                    className="form-input-error"
                                ></span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="form-group">
                                <Form.Label>Campaign</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="campaign"
                                    defaultValue={props?.campaignData?.campaign}
                                />
                                <span
                                    id="form-error-campaign"
                                    className="form-input-error"
                                ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h4 className="form-heading">Schedule</h4>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    Start date (UTC) <span>*</span>
                                </Form.Label>
                                <DatePicker
                                    className="form-control"
                                    name="startDate"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                />
                            </Form.Group>
                            <span
                                id="form-error-startDate"
                                className="form-input-error"
                            ></span>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="form-group">
                                <Form.Label>
                                    End date (UTC) <span>*</span>
                                </Form.Label>
                                <DatePicker
                                    className="form-control"
                                    name="endDate"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                />
                            </Form.Group>
                            <span
                                id="form-error-endDate"
                                className="form-input-error"
                            ></span>
                        </Col>
                    </Row>
                    <Accordion className="custom-home-accordion" defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Dayparts</Accordion.Header>
                            <Accordion.Body>
                                <div className="add-morebtn">
                                    <p>
                                        Edit here your advertising schedule.
                                        Hours are in UTC.
                                    </p>
                                    <div className="btn btn-medium btn-info" onClick={addScheduleDiv}>
                                        Add
                                    </div>
                                </div>
                                <div className="dayparts-list">
                                    {scheduleDivs.map((div) => (
                                        <div key={div.weekId} className="daypart-box">
                                            <input type="hidden" name={`schedule[${div.weekId}][weekId]`} value={div.weekId}/>
                                            <Row>
                                                <Col md={3}>
                                                    <Form.Select name={`schedule[${div.weekId}][weekType]`} aria-label="weeks-select" defaultValue={div.weekType}>
                                                        <option value="All">
                                                            All Days
                                                        </option>
                                                        <option value="From Monday to Friday">
                                                            From Monday to Friday
                                                        </option>
                                                        <option value="All Weekend">
                                                            All Weekend
                                                        </option>
                                                        <option value="Monday">
                                                            Monday
                                                        </option>
                                                        <option value="Tuesday">
                                                            Tuesday
                                                        </option>
                                                        <option value="Wednesday">
                                                            Wednesday
                                                        </option>
                                                        <option value="Thursday">
                                                            Thursday
                                                        </option>
                                                        <option value="Friday">
                                                            Friday
                                                        </option>
                                                        <option value="Saturday">
                                                            Saturday
                                                        </option>
                                                        <option value="Sunday">
                                                            Sunday
                                                        </option>
                                                    </Form.Select>
                                                </Col>
                                                <Col md={9}>
                                                    <div className="hours-mints-block">
                                                        <div className="from-to-min-hrs">
                                                            <span className="ftmh-head">
                                                                From
                                                            </span>
                                                            <Form.Select name={`schedule[${div.weekId}][weekTypeFH]`} aria-label="weeks-select" defaultValue={div.weekTypeFH}>
                                                                <option value="00">
                                                                    00
                                                                </option>
                                                                <option value="01">
                                                                    01
                                                                </option>
                                                                <option value="02">
                                                                    02
                                                                </option>
                                                                <option value="03">
                                                                    03
                                                                </option>
                                                                <option value="04">
                                                                    04
                                                                </option>
                                                                <option value="05">
                                                                    05
                                                                </option>
                                                                <option value="06">
                                                                    06
                                                                </option>
                                                                <option value="07">
                                                                    07
                                                                </option>
                                                                <option value="08">
                                                                    08
                                                                </option>
                                                                <option value="09">
                                                                    09
                                                                </option>
                                                                <option value="10">
                                                                    10
                                                                </option>
                                                                <option value="11">
                                                                    11
                                                                </option>
                                                                <option value="12">
                                                                    12
                                                                </option>
                                                                <option value="13">
                                                                    13
                                                                </option>
                                                                <option value="14">
                                                                    14
                                                                </option>
                                                                <option value="15">
                                                                    15
                                                                </option>
                                                                <option value="16">
                                                                    16
                                                                </option>
                                                                <option value="17">
                                                                    17
                                                                </option>
                                                                <option value="18">
                                                                    18
                                                                </option>
                                                                <option value="19">
                                                                    19
                                                                </option>
                                                                <option value="20">
                                                                    20
                                                                </option>
                                                                <option value="21">
                                                                    21
                                                                </option>
                                                                <option value="22">
                                                                    22
                                                                </option>
                                                                <option value="23">
                                                                    23
                                                                </option>
                                                            </Form.Select>
                                                            <span>h</span>
                                                            <span className="divider-blk">
                                                                :
                                                            </span>
                                                            <Form.Select name={`schedule[${div.weekId}][weekTypeFM]`} aria-label="weeks-select" defaultValue={div.weekTypeFM}>
                                                                <option value="00">
                                                                    00
                                                                </option>
                                                                <option value="15">
                                                                    15
                                                                </option>
                                                                <option value="30">
                                                                    30
                                                                </option>
                                                                <option value="45">
                                                                    45
                                                                </option>
                                                            </Form.Select>
                                                            <span>min</span>
                                                        </div>
                                                        <div className="from-to-min-hrs">
                                                            <span className="ftmh-head">
                                                                To
                                                            </span>
                                                            <Form.Select name={`schedule[${div.weekId}][weekTypeTH]`} aria-label="weeks-select" defaultValue={div.weekTypeTH}>
                                                                <option value="00">
                                                                    00
                                                                </option>
                                                                <option value="01">
                                                                    01
                                                                </option>
                                                                <option value="02">
                                                                    02
                                                                </option>
                                                                <option value="03">
                                                                    03
                                                                </option>
                                                                <option value="04">
                                                                    04
                                                                </option>
                                                                <option value="05">
                                                                    05
                                                                </option>
                                                                <option value="06">
                                                                    06
                                                                </option>
                                                                <option value="07">
                                                                    07
                                                                </option>
                                                                <option value="08">
                                                                    08
                                                                </option>
                                                                <option value="09">
                                                                    09
                                                                </option>
                                                                <option value="10">
                                                                    10
                                                                </option>
                                                                <option value="11">
                                                                    11
                                                                </option>
                                                                <option value="12">
                                                                    12
                                                                </option>
                                                                <option value="13">
                                                                    13
                                                                </option>
                                                                <option value="14">
                                                                    14
                                                                </option>
                                                                <option value="15">
                                                                    15
                                                                </option>
                                                                <option value="16">
                                                                    16
                                                                </option>
                                                                <option value="17">
                                                                    17
                                                                </option>
                                                                <option value="18">
                                                                    18
                                                                </option>
                                                                <option value="19">
                                                                    19
                                                                </option>
                                                                <option value="20">
                                                                    20
                                                                </option>
                                                                <option value="21">
                                                                    21
                                                                </option>
                                                                <option value="22">
                                                                    22
                                                                </option>
                                                                <option value="23">
                                                                    23
                                                                </option>
                                                            </Form.Select>
                                                            <span>h</span>
                                                            <span className="divider-blk">
                                                                :
                                                            </span>
                                                            <Form.Select name={`schedule[${div.weekId}][weekTypeTM]`} aria-label="weeks-select" defaultValue={div.weekTypeTM}>
                                                                <option value="14">
                                                                    14
                                                                </option>
                                                                <option value="29">
                                                                    29
                                                                </option>
                                                                <option value="44">
                                                                    44
                                                                </option>
                                                                <option value="59">
                                                                    59
                                                                </option>
                                                            </Form.Select>
                                                            <span>min</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="delete-button-block">
                                                <div className="btn btn-nobg btn-medium" onClick={() => deleteDiv(div.weekId)}>
                                                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <span
                        id="form-error-schedule"
                        className="form-input-error"
                    ></span>

                    <h4 className="form-heading">Advertiser</h4>
                    <Row>
                        <Col md={6}>
                            <Form.Group id="form-group-representativeCategory" className="form-group">
                                <Form.Label>
                                    Representative category <span>*</span>
                                </Form.Label>
                                <Select
                                    options={availableOptions}
                                    onChange={handleChange}
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.value}
                                    placeholder=" "
                                    name="representativeCategory"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={selectedOption}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group id="form-group-domain" className="form-group">
                                <Form.Label>
                                    Domain <span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="url"
                                    name="domain"
                                    defaultValue={props?.campaignData?.domain}
                                />
                                <span
                                    id="form-error-domain"
                                    className="form-input-error"
                                ></span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="form-group">
                                <Form.Label>Bundle</Form.Label>
                                <Form.Control
                                    type="url"
                                    name="bundle"
                                    defaultValue={props?.campaignData?.bundle}
                                />
                                <span
                                    id="form-error-bundle"
                                    className="form-input-error"
                                ></span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group id="form-group-landingPageUrl" className="form-group">
                                <Form.Label>
                                    Landing page URL <span>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="url"
                                    name="landingPageUrl"
                                    defaultValue={props?.campaignData?.landingPageUrl}
                                />
                                <span
                                    id="form-error-landingPageUrl"
                                    className="form-input-error"
                                ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <ActionButton saveData={saveData} tinyloader={tinyloader} />
                </Form>
            </div>
            <Modal size="sm" show={Modal1} onHide={handleClose1} centered className="custom-model-block missing-data-block">
                <Modal.Body>
                <div className="close-icon-block" onClick={handleClose1}><FontAwesomeIcon icon="fa-solid fa-xmark" /></div>
                    <div className="success-campaign-block">
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                        <h3>Missing Data</h3>
                        <p>Mandatory data/input is missing</p>
                        <Button variant="primary" className="btn-medium" onClick={setContinue}>Continue regardless</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main;
