import { Table } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { Context } from '../../../components/Context';
import { PageLoader } from '../../../components/Helper';
import moment from 'moment';
import { fetchData, REPORT_EPOM } from '../../../components/Service';
import $ from 'jquery';
import 'datatables.net-dt/css/dataTables.dataTables.min.css'; // DataTables CSS
import 'datatables.net'; // DataTables JS

const EpomReport = (props) => {
    const [context] = useContext(Context)

    const getEpomReport = (e) => {
        PageLoader(true)
        fetchData(REPORT_EPOM, 'GET', "", true, false, (res) => {
            PageLoader(false)
            if (res.status === "success") {
                $('#epomReport').DataTable({
                    data: res.records.campaigns,
                    columns: [
                        { title: 'ID', data: 'id',
                            render: function(data) {
                                return '<span class="dt-id-block">'+data.substring(0, 6)+'</span>';
                            }
                        },
                        { title: 'Name', data: 'name' },
                        { title: 'Status', data: 'status.name',
                            render: function (data) {
                                return '<span class="status-block '+data.toLowerCase()+'">'+data.toUpperCase()+'</span>';
                            }
                         },
                        // { title: 'Actions', data: null, defaultContent: "" },
                        { title: 'Lines Active', data: null, defaultContent: 0 },
                        { title: 'Total Spend', data: null, defaultContent: 0,
                            render: function(data) {
                                return data > 0 ? '$ ' + data : 0;
                            }
                        },
                        { title: 'Daily Budget', data: 'dailyBudget', defaultContent: 0,
                            render: function(data) {
                                return data > 0 ? '$ ' + data : 0;
                            }
                        },
                        { title: 'Total Budget', data: 'maxSpend', defaultContent: 0,
                            render: function(data) {
                                return data > 0 ? '$ ' + data : 0;
                            }
                        },
                        { title: 'CPM', data: null, defaultContent: 0,
                            render: function(data) {
                                return data > 0 ? '$ ' + data : 0;
                            }
                        },
                        { title: 'CPC', data: null, defaultContent: 0,
                            render: function(data) {
                                return data > 0 ? '$ ' + data : 0;
                            }
                        },
                        { title: 'CTR', data: null, defaultContent: 0,
                            render: function(data) {
                                return data > 0 ? data+ '%' : 0;
                            }
                        },
                        { title: 'Impressions', data: 'campaignLimit.generalLimit.maxImpressions', defaultContent: 0 },
                        { title: 'Clicks', data: 'campaignLimit.generalLimit.maxClicks', defaultContent: 0 },
                        { title: 'Landings', data: null, defaultContent: 0 },
                        { title: 'Start Date', data: 'startDate',
                            render: function (data) {
                                return moment(new Date(data).toString()).format('YYYY-MM-DD');
                            }
                         },
                        { title: 'End Date', data: 'endDate',
                            render: function (data) {
                                return moment(new Date(data).toString()).format('YYYY-MM-DD');
                            }
                        },
                    ],
                    destroy: true, // Allow re-initialization
                });
            } else {
                // displayError(res.errors)
            }
        });
    }

    useEffect(() => {
        if(context && context.id){
            getEpomReport()
        }
    }, [])

    return (
        <>
            <Table id="epomReport" responsive bordered hover></Table>
        </>
    );
};

export default EpomReport;
