import { Form } from "react-bootstrap";
import EpomReport from './partials/EpomReport';
import { useState } from "react";
import MediaSmartReport from "./partials/MediaSmartReport";
import SmadexReport from "./partials/SmadexReport";
import SmartyAdsReport from "./partials/SmartyAdsReport";

const Reporting = () => {
    const [dsp, setDsp] = useState("Epom");

    return (
        <div className="dashboard-content-block">
            <div className="dashbaord-heading">
                DSP Reporting
            </div>
            <div className="select-dsp-block">
            <Form.Group className="form-group">
                <Form.Label>Select DSP</Form.Label>
                <Form.Select onChange={(e) => setDsp(e.target.value)}>
                    <option value="Epom" defaultChecked>
                        Epom
                    </option>
                    <option value="Mediasmart">
                        Mediasmart
                    </option>
                    <option value="Smadex">
                        Smadex
                    </option>
                    <option value="SmartyAds">
                        SmartyAds
                    </option>
                </Form.Select>
            </Form.Group>
            </div>
            <div className="custom-datatable-block">
                { dsp === "Epom" && <EpomReport/>}
                { dsp === "Mediasmart" && <MediaSmartReport/>}
                { dsp === "Smadex" && <SmadexReport/>}
                { dsp === "SmartyAds" && <SmartyAdsReport/>}
            </div>
        </div>
    );
};

export default Reporting;