import { useContext, useEffect, useState } from "react";
import {
    Row,
    Col,
    Tab,
    Tabs,
    Form,
    Table,
    Pagination,
    Tooltip,
    OverlayTrigger,
    Button,
    Modal,
    Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Context } from "../../../components/Context";
import { CAMPAIGN_GEO, fetchData } from "../../../components/Service";
import { ActionButton, displayError, DownloadButton, uploadFileToAWSBucket } from "../../../components/Helper";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const Geo = (props) => {
    const navigate = useNavigate()
    const [Modal1, setModal1] = useState(false);
    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const [wholeCountry, setWholeCountry] = useState(false);
    const [activeZone, setActiveZone] = useState("regions")

    const countries = [
        { "value": "AFG", "label": "Afghanistan" },
        { "value": "ALB", "label": "Albania" },
        { "value": "DZA", "label": "Algeria" },
        { "value": "AND", "label": "Andorra" },
        { "value": "AGO", "label": "Angola" },
        { "value": "ATG", "label": "Antigua and Barbuda" },
        { "value": "ARG", "label": "Argentina" },
        { "value": "ARM", "label": "Armenia" },
        { "value": "AUS", "label": "Australia" },
        { "value": "AUT", "label": "Austria" },
        { "value": "AZE", "label": "Azerbaijan" },
        { "value": "BHS", "label": "Bahamas" },
        { "value": "BHR", "label": "Bahrain" },
        { "value": "BGD", "label": "Bangladesh" },
        { "value": "BRB", "label": "Barbados" },
        { "value": "BLR", "label": "Belarus" },
        { "value": "BEL", "label": "Belgium" },
        { "value": "BLZ", "label": "Belize" },
        { "value": "BEN", "label": "Benin" },
        { "value": "BTN", "label": "Bhutan" },
        { "value": "BOL", "label": "Bolivia" },
        { "value": "BIH", "label": "Bosnia and Herzegovina" },
        { "value": "BWA", "label": "Botswana" },
        { "value": "BRA", "label": "Brazil" },
        { "value": "BRN", "label": "Brunei Darussalam" },
        { "value": "BGR", "label": "Bulgaria" },
        { "value": "BFA", "label": "Burkina Faso" },
        { "value": "BDI", "label": "Burundi" },
        { "value": "CPV", "label": "Cabo Verde" },
        { "value": "KHM", "label": "Cambodia" },
        { "value": "CMR", "label": "Cameroon" },
        { "value": "CAN", "label": "Canada" },
        { "value": "CAF", "label": "Central African Republic" },
        { "value": "TCD", "label": "Chad" },
        { "value": "CHL", "label": "Chile" },
        { "value": "CHN", "label": "China" },
        { "value": "COL", "label": "Colombia" },
        { "value": "COM", "label": "Comoros" },
        { "value": "COG", "label": "Congo (Congo-Brazzaville)" },
        { "value": "CRI", "label": "Costa Rica" },
        { "value": "HRV", "label": "Croatia" },
        { "value": "CUB", "label": "Cuba" },
        { "value": "CYP", "label": "Cyprus" },
        { "value": "CZE", "label": "Czechia (Czech Republic)" },
        { "value": "DNK", "label": "Denmark" },
        { "value": "DJI", "label": "Djibouti" },
        { "value": "DMA", "label": "Dominica" },
        { "value": "DOM", "label": "Dominican Republic" },
        { "value": "TLS", "label": "East Timor" },
        { "value": "ECU", "label": "Ecuador" },
        { "value": "EGY", "label": "Egypt" },
        { "value": "SLV", "label": "El Salvador" },
        { "value": "GNQ", "label": "Equatorial Guinea" },
        { "value": "ERI", "label": "Eritrea" },
        { "value": "EST", "label": "Estonia" },
        { "value": "SWZ", "label": "Eswatini (fmr. 'Swaziland')" },
        { "value": "ETH", "label": "Ethiopia" },
        { "value": "FJI", "label": "Fiji" },
        { "value": "FIN", "label": "Finland" },
        { "value": "FRA", "label": "France" },
        { "value": "GAB", "label": "Gabon" },
        { "value": "GMB", "label": "Gambia" },
        { "value": "GEO", "label": "Georgia" },
        { "value": "DEU", "label": "Germany" },
        { "value": "GHA", "label": "Ghana" },
        { "value": "GRC", "label": "Greece" },
        { "value": "GRD", "label": "Grenada" },
        { "value": "GTM", "label": "Guatemala" },
        { "value": "GIN", "label": "Guinea" },
        { "value": "GNB", "label": "Guinea-Bissau" },
        { "value": "GUY", "label": "Guyana" },
        { "value": "HTI", "label": "Haiti" },
        { "value": "HND", "label": "Honduras" },
        { "value": "HUN", "label": "Hungary" },
        { "value": "ISL", "label": "Iceland" },
        { "value": "IND", "label": "India" },
        { "value": "IDN", "label": "Indonesia" },
        { "value": "IRN", "label": "Iran" },
        { "value": "IRQ", "label": "Iraq" },
        { "value": "IRL", "label": "Ireland" },
        { "value": "ISR", "label": "Israel" },
        { "value": "ITA", "label": "Italy" },
        { "value": "JAM", "label": "Jamaica" },
        { "value": "JPN", "label": "Japan" },
        { "value": "JOR", "label": "Jordan" },
        { "value": "KAZ", "label": "Kazakhstan" },
        { "value": "KEN", "label": "Kenya" },
        { "value": "KIR", "label": "Kiribati" },
        { "value": "PRK", "label": "Korea (North)" },
        { "value": "KOR", "label": "Korea (South)" },
        { "value": "XKX", "label": "Kosovo" },
        { "value": "KWT", "label": "Kuwait" },
        { "value": "KGZ", "label": "Kyrgyzstan" },
        { "value": "LAO", "label": "Laos" },
        { "value": "LVA", "label": "Latvia" },
        { "value": "LBN", "label": "Lebanon" },
        { "value": "LSO", "label": "Lesotho" },
        { "value": "LBR", "label": "Liberia" },
        { "value": "LBY", "label": "Libya" },
        { "value": "LIE", "label": "Liechtenstein" },
        { "value": "LTU", "label": "Lithuania" },
        { "value": "LUX", "label": "Luxembourg" },
        { "value": "MDG", "label": "Madagascar" },
        { "value": "MWI", "label": "Malawi" },
        { "value": "MYS", "label": "Malaysia" },
        { "value": "MDV", "label": "Maldives" },
        { "value": "MLI", "label": "Mali" },
        { "value": "MLT", "label": "Malta" },
        { "value": "MHL", "label": "Marshall Islands" },
        { "value": "MRT", "label": "Mauritania" },
        { "value": "MUS", "label": "Mauritius" },
        { "value": "MEX", "label": "Mexico" },
        { "value": "FSM", "label": "Micronesia" },
        { "value": "MDA", "label": "Moldova" },
        { "value": "MCO", "label": "Monaco" },
        { "value": "MNG", "label": "Mongolia" },
        { "value": "MNE", "label": "Montenegro" },
        { "value": "MAR", "label": "Morocco" },
        { "value": "MOZ", "label": "Mozambique" },
        { "value": "MMR", "label": "Myanmar (formerly Burma)" },
        { "value": "NAM", "label": "Namibia" },
        { "value": "NRU", "label": "Nauru" },
        { "value": "NPL", "label": "Nepal" },
        { "value": "NLD", "label": "Netherlands" },
        { "value": "NZL", "label": "New Zealand" },
        { "value": "NIC", "label": "Nicaragua" },
        { "value": "NER", "label": "Niger" },
        { "value": "NGA", "label": "Nigeria" },
        { "value": "MKD", "label": "North Macedonia" },
        { "value": "NOR", "label": "Norway" },
        { "value": "OMN", "label": "Oman" },
        { "value": "PAK", "label": "Pakistan" },
        { "value": "PLW", "label": "Palau" },
        { "value": "PAN", "label": "Panama" },
        { "value": "PNG", "label": "Papua New Guinea" },
        { "value": "PRY", "label": "Paraguay" },
        { "value": "PER", "label": "Peru" },
        { "value": "PHL", "label": "Philippines" },
        { "value": "POL", "label": "Poland" },
        { "value": "PRT", "label": "Portugal" },
        { "value": "QAT", "label": "Qatar" },
        { "value": "ROU", "label": "Romania" },
        { "value": "RUS", "label": "Russia" },
        { "value": "RWA", "label": "Rwanda" },
        { "value": "KNA", "label": "Saint Kitts and Nevis" },
        { "value": "LCA", "label": "Saint Lucia" },
        { "value": "VCT", "label": "Saint Vincent and the Grenadines" },
        { "value": "WSM", "label": "Samoa" },
        { "value": "SMR", "label": "San Marino" },
        { "value": "STP", "label": "Sao Tome and Principe" },
        { "value": "SAU", "label": "Saudi Arabia" },
        { "value": "SEN", "label": "Senegal" },
        { "value": "SRB", "label": "Serbia" },
        { "value": "SYC", "label": "Seychelles" },
        { "value": "SLE", "label": "Sierra Leone" },
        { "value": "SGP", "label": "Singapore" },
        { "value": "SVK", "label": "Slovakia" },
        { "value": "SVN", "label": "Slovenia" },
        { "value": "SLB", "label": "Solomon Islands" },
        { "value": "SOM", "label": "Somalia" },
        { "value": "ZAF", "label": "South Africa" },
        { "value": "SSD", "label": "South Sudan" },
        { "value": "ESP", "label": "Spain" },
        { "value": "LKA", "label": "Sri Lanka" },
        { "value": "SDN", "label": "Sudan" },
        { "value": "SUR", "label": "Suriname" },
        { "value": "SWE", "label": "Sweden" },
        { "value": "CHE", "label": "Switzerland" },
        { "value": "SYR", "label": "Syria" },
        { "value": "TWN", "label": "Taiwan" },
        { "value": "TJK", "label": "Tajikistan" },
        { "value": "TZA", "label": "Tanzania" },
        { "value": "THA", "label": "Thailand" },
        { "value": "TGO", "label": "Togo" },
        { "value": "TON", "label": "Tonga" },
        { "value": "TTO", "label": "Trinidad and Tobago" },
        { "value": "TUN", "label": "Tunisia" },
        { "value": "TUR", "label": "Turkey" },
        { "value": "TKM", "label": "Turkmenistan" },
        { "value": "TUV", "label": "Tuvalu" },
        { "value": "UGA", "label": "Uganda" },
        { "value": "UKR", "label": "Ukraine" },
        { "value": "ARE", "label": "United Arab Emirates" },
        { "value": "GBR", "label": "United Kingdom" },
        { "value": "USA", "label": "United States of America" },
        { "value": "URY", "label": "Uruguay" },
        { "value": "UZB", "label": "Uzbekistan" },
        { "value": "VUT", "label": "Vanuatu" },
        { "value": "VAT", "label": "Vatican City" },
        { "value": "VEN", "label": "Venezuela" },
        { "value": "VNM", "label": "Vietnam" },
        { "value": "YEM", "label": "Yemen" },
        { "value": "ZMB", "label": "Zambia" },
        { "value": "ZWE", "label": "Zimbabwe" }
    ];      

    const Addtooltip = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            {children}
        </OverlayTrigger>
    );

    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState("")
    const [listType, setListType] = useState("allow")
    const [precision, setPrecision] = useState("")

    const setContinue = () => {
        handleClose1()
        props.makeTabAcive("creatives")
    }

    const saveData = (e) => {
        let formData = new FormData(document.getElementById("campaign-geo"));

        if(selectedCountries === "" || !selectedCountries.length) {
            handleShow1();
        }

        let data = {
            userId: context && context.id ? context.id : 0,
            campaignId: context && context.campaignId ? context.campaignId : "",
            geoTargetCountry: selectedCountries,
            geoTargetWholeCountry: wholeCountry,
            geoTargetZoneType: activeZone,
            geoTargetListType: listType, 
            geoTargetAllowedZone:allowFile,
            geoTargetDeniedZone: denyFile,
            geoTargetZoneSelected: "",
            geoTargetPrecision: activeZone === "cities" ? "" : precision,
        };

        setTinyloader(true);

        fetchData(CAMPAIGN_GEO, "POST", data, true, false, (res) => {
            setTinyloader(false);
            displayError(res.errors);
            if (res.status === "success") {
                setContext(prevValue => ({
                    ...prevValue,
                    campaignId: res?.records?.id,
                    tabName: res?.records?.formNamePath
                }))
                localStorage.setItem('__t6t_campaign_id', res?.records?.id)
                props.makeTabAcive("", res?.records?.id)
            }
        });
    };

    useEffect(() => {
        if(props && props.campaignData) {
            setSelectedCountries(props.campaignData.geoTargetCountry ? JSON.parse(props.campaignData.geoTargetCountry) : "")
            setWholeCountry(props.campaignData.geoTargetWholeCountry === "1" ? true : false)
            setActiveZone(props.campaignData.geoTargetZoneType ? props.campaignData.geoTargetZoneType : "regions")
            setListType(props.campaignData.geoTargetListType ? props.campaignData.geoTargetListType : "allow")
            setPrecision(props.campaignData.geoTargetPrecision ? props.campaignData.geoTargetPrecision : "") 
            setAllowFile(props.campaignData.geoTargetAllowedZone ? props.campaignData.geoTargetAllowedZone : "") 
            setDenyFile(props.campaignData.geoTargetDeniedZone ? props.campaignData.geoTargetDeniedZone : "") 
        }
    }, [props])

    // Region to track selected rows
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    // Example data with 20 regions
    const data = [
        { id: 1, name: 'North America' },
        { id: 2, name: 'South America' },
        { id: 3, name: 'Europe' },
        { id: 4, name: 'Asia' },
        { id: 5, name: 'Africa' },
        { id: 6, name: 'Oceania' },
        { id: 7, name: 'Central America' },
        { id: 8, name: 'Caribbean' },
        { id: 9, name: 'Middle East' },
        { id: 10, name: 'Eastern Europe' },
        { id: 11, name: 'Western Europe' },
        { id: 12, name: 'Northern Europe' },
        { id: 13, name: 'Southern Europe' },
        { id: 14, name: 'Southeast Asia' },
        { id: 15, name: 'South Asia' },
        { id: 16, name: 'East Asia' },
        { id: 17, name: 'Central Asia' },
        { id: 18, name: 'Sub-Saharan Africa' },
        { id: 19, name: 'North Africa' },
        { id: 20, name: 'Australia and New Zealand' }
    ];

    // Filter data based on search term
    const filteredData = data.filter(region =>
      region.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Calculate pagination
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  
    // Calculate rows to display on the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  
    const handleRowClick = (id) => {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.includes(id)
          ? prevSelectedRows.filter((rowId) => rowId !== id)
          : [...prevSelectedRows, id]
      );
    };
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const renderPaginationItems = () => {
      const items = [];
      const pageCount = 4; // Number of page numbers to show at a time
      let startPage = Math.max(1, currentPage - Math.floor(pageCount / 2));
      let endPage = Math.min(totalPages, startPage + pageCount - 1);
  
      if (endPage - startPage < pageCount - 1) {
        startPage = Math.max(1, endPage - pageCount + 1);
      }
  
      items.push(
        <Pagination.First
          key="first"
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
          className="doble-angle"
        />
      );
  
      items.push(
        <Pagination.Prev
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="single-angle"
        />
      );
  
      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
  
      items.push(
        <Pagination.Next
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="single-angle"
        />
      );
  
      items.push(
        <Pagination.Last
          key="last"
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
          className="doble-angle"
        />
      );
  
      return items;
    };

    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const [allowFile, setAllowFile] = useState("")
    const [denyFile, setDenyFile] = useState("")

    const uploadListFiles = async (e, type) => {
        setFileUploadLoader(type)
        let file = await uploadFileToAWSBucket(e)
        setFileUploadLoader(false)
        
        if(type === "allow") {
            setAllowFile(file)
        } else {
            setDenyFile(file)
        }
    }

    return (
        <>
            <div className="tab-inner-content">
                <Form id="campaign-geo">
                    <h4 className="form-heading">Geotargeting</h4>
                    <Form.Group id="form-group-geoTargetCountry" className="form-group">
                        <Form.Label>
                            Countries <span>*</span>
                        </Form.Label>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={countries}
                            name="geoTargetCountry"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={selectedCountries}
                            onChange={(value) => setSelectedCountries(value)}
                        />
                        <span
                            id="form-error-geoTargetCountry"
                            className="form-input-error"
                        ></span>
                    </Form.Group>
                    <div className="wholecountry-block">
                        <label className={wholeCountry ? "checkbox-block selected" : "checkbox-block"}>
                            <div className="wholecountry-label-cont">
                                <h5>Whole Country</h5>
                                <p>
                                    By selecting this option you are not allowed
                                    to target any specific city, location,
                                    region or zipcode
                                </p>
                            </div>
                            <input
                                type="checkbox"
                                checked={wholeCountry}
                                onChange={(e) => setWholeCountry(e.target.checked)}
                            />
                            <span className="checkmark"></span>
                            <b>
                                <FontAwesomeIcon icon="fa-solid fa-earth-americas" />
                            </b>
                        </label>
                    </div>
                    { !wholeCountry &&
                        <div className="geo-tab-section">
                            <Tabs
                                variant="pills"
                                id="geo-tab-pills"
                                justify
                                activeKey={activeZone}
                                onSelect={(k) => {
                                    setActiveZone(k)
                                    if(k !== "cities" && (precision === "" || !precision)) {
                                        setPrecision('gps');
                                    }
                                }}
                                className=""
                            >
                                <Tab
                                    eventKey="regions"
                                    title={
                                        <span>
                                            <FontAwesomeIcon icon="fa-solid fa-map-location-dot" />{" "}
                                            <p>Regions</p>
                                        </span>
                                    }
                                >
                                </Tab>
                                <Tab
                                    eventKey="cities"
                                    title={
                                        <span>
                                            <FontAwesomeIcon icon="fa-solid fa-city" />{" "}
                                            <p>Cities</p>
                                        </span>
                                    }
                                >
                                </Tab>
                                <Tab
                                    eventKey="zipcodes"
                                    title={
                                        <span>
                                            <FontAwesomeIcon icon="fa-solid fa-house" />{" "}
                                            <p>Zipcodes</p>
                                        </span>
                                    }
                                >
                                </Tab>
                                <Tab
                                    eventKey="locations"
                                    title={
                                        <span>
                                            <FontAwesomeIcon icon="fa-solid fa-location-arrow" />{" "}
                                            <p>Locations</p>
                                        </span>
                                    }
                                >
                                </Tab>
                            </Tabs>
                            <div className="heading-wth-subheading">
                                <h4 className="form-heading">{_.startCase(activeZone)}</h4>
                                <p className="subheading">List type</p>
                            </div>
                            <Row>
                                <Col>
                                    <div className={`list-type-selector ${listType === "allow" && "selected"}`} onClick={() => setListType("allow")}>
                                        <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                                        <h5>Allow list</h5>
                                        <p>
                                            All the {activeZone} selected will be
                                            included in the targeting
                                        </p>
                                    </div>
                                    <div className="form-group">
                                        <div className="double-field-block">
                                            <div className="file-upload-block">
                                            <Form.Control type="file" onChange={(e) => uploadListFiles(e, "allow")}/>
                                            <Button variant="light" className="file-upload-btn"> { fileUploadLoader === "allow" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload File</Button>
                                            </div>
                                            <span className="ps-2">{allowFile}</span> { props && props.campaignData && props.campaignData.geoTargetAllowedZone && <DownloadButton url={props.campaignData.geoTargetAllowedZone} /> }
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={`list-type-selector ${listType === "deny" && "selected"}`} onClick={() => setListType("deny")}>
                                        <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                                        <h5>Deny list</h5>
                                        <p>
                                            All the {activeZone} selected will be
                                            denied in the targeting
                                        </p>
                                    </div>
                                    <div className="form-group">
                                        <div className="double-field-block">
                                            <div className="file-upload-block">
                                            <Form.Control type="file" onChange={(e) => uploadListFiles(e, "deny")}/>
                                            <Button variant="light" className="file-upload-btn"> { fileUploadLoader === "deny" ? <Spinner animation="grow" size="sm" className='me-2'/> : <FontAwesomeIcon icon="fa-solid fa-upload" /> } Upload File</Button>
                                            </div>
                                            <span className="ps-2">{denyFile}</span> { props && props.campaignData && props.campaignData.geoTargetDeniedZone && <DownloadButton url={props.campaignData.geoTargetDeniedZone} /> }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="location-list-avilable-select-block">
                                <Row>
                                    <Col className="col50">
                                        <h5>Available</h5>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search by id and name"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                // style={{ margin: '20px', padding: '10px' }}
                                            />
                                        </Form.Group>
                                        <div className="as-list-table-blk">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentRows.map((region) => (
                                                        <tr
                                                        key={region.id}
                                                        onClick={() => handleRowClick(region.id)}
                                                        className={selectedRows.includes(region.id) ? 'selected' : ''}
                                                        >
                                                        <td>{region.id}</td>
                                                        <td>{region.name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <div className="as-pagination-blk">

                                                <Pagination>{renderPaginationItems()}</Pagination>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="col10 arrow-selection">
                                        <Addtooltip
                                            title="Add Value"
                                            id="arrow-1"
                                        >
                                            <div className="arrow-selection-icon">
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                            </div>
                                        </Addtooltip>
                                        <Addtooltip
                                            title="Remove Value"
                                            id="arrow-2"
                                        >
                                            <div className="arrow-selection-icon">
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                                            </div>
                                        </Addtooltip>
                                    </Col>
                                    <Col className="col50">
                                        <h5>Selected</h5>
                                        <Form.Group className="form-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search by id and name"
                                            />
                                        </Form.Group>
                                        <div className="as-list-table-blk selected-table">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="no-item-show">
                                                        <td colSpan="2">
                                                            No Regions
                                                            selected
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            { activeZone !== "cities" && <div className="geoprecision-block">
                                <h4 className="form-heading">
                                    Geo precision
                                </h4>
                                <Row>
                                    <Col xs={3} onClick={() => setPrecision("gps")}>
                                        <div className={`list-type-selector ${precision === "gps" && "selected"}`}>
                                            <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                                            <h5>GPS</h5>
                                        </div>
                                    </Col>
                                    <Col xs={3} onClick={() => setPrecision("ip")}>
                                        <div className={`list-type-selector ${precision === "ip" && "selected"}`}>
                                            <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                                            <h5>IP</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </div>}
                        </div>
                    }
                    <ActionButton saveData={saveData} tinyloader={tinyloader} />
                </Form>
            </div>
            <Modal size="sm" show={Modal1} onHide={handleClose1} centered className="custom-model-block missing-data-block">
                <Modal.Body>
                <div className="close-icon-block" onClick={handleClose1}><FontAwesomeIcon icon="fa-solid fa-xmark" /></div>
                    <div className="success-campaign-block">
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                        <h3>Missing Data</h3>
                        <p>Mandatory data/input is missing</p>
                        <Button variant="primary" className="btn-medium" onClick={setContinue}>Continue regardless</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Geo;
