import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Aside from '../../components/Aside';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../components/Context';
import { USER_DETAIL, USER_UPDATE, fetchData } from '../../components/Service';
import { displayError } from '../../components/Helper';

const Setting = () => {
    const [context] = useContext(Context)
    const [tinyLoader, setTinyLoader] = useState(false)

    const [userData, setUserData] = useState()

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('update-user'));
        
        let data = {
            id: userData && userData.id,
            firstName:  formData.get("firstName"),
            lastName:  formData.get("lastName"),
            userName:  formData.get("userName"),
            phone:  formData.get("phone"),
            email:  formData.get("email"),
            phoneAlt:  formData.get("phoneAlt"),
            emailAlt:  formData.get("emailAlt"),
            password:  formData.get("password"),
        }

        setTinyLoader(type)

        fetchData(USER_UPDATE, 'POST', data, true, false, (res) => {
            setTinyLoader(false);
            if (res.status !== "success") {
                displayError(res.errors)
            }
        });
    }

    const getUser = () => {
        let id = context && context.id

        fetchData(USER_DETAIL + "/" + id, 'GET', "", true, false, (res) => {
            if (res.status === "success") {
                setUserData(res.records)
            } else {
                // displayError(res.errors)
            }
        });
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div className="dashboard-content-block">
            <div className="dashbaord-heading">
                Edit Admin Details
            </div>
            <div className="admin-form-block">
                <Form id="update-user">
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>First Name *</Form.Label>
                                <Form.Control type="text" name="firstName" defaultValue={userData && userData.firstName}/>
                                <span id="form-error-firstName" className='form-input-error'></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="lastName" defaultValue={userData && userData.lastName}/>
                                <span id="form-error-lastName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email Id *</Form.Label>
                                <Form.Control type="email" name="email" defaultValue={userData && userData.email}/>
                                <span id="form-error-email" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Secondary Email Id *</Form.Label>
                                <Form.Control type="email" name="emailAlt" defaultValue={userData && userData.emailAlt}/>
                                <span id="form-error-email" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Phone Number *</Form.Label>
                                <Form.Control type="text" name="phone" defaultValue={userData && userData.phone}/>
                                <span id="form-error-phone" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Alternate Phone Number</Form.Label>
                                <Form.Control type="text" name="phoneAlt" defaultValue={userData && userData.phoneAlt}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username *</Form.Label>
                                <Form.Control type="text" name="userName" defaultValue={userData && userData.userName}/>
                                <span id="form-error-userName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password *</Form.Label>
                                <Form.Control type="text" name="password"/>
                                <span className='small text-muted'>Leave it blank, if do not want to change.</span>
                                <span id="form-error-password" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="dash-buttons-block">
                        <Button variant="primary" onClick={(e) => saveData(e, 'update')} disabled={tinyLoader === "update"}>{ tinyLoader === "update" && <Spinner animation="grow" size="sm" /> } Update Setting</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
};

export default Setting;