import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Aside from '../../components/Aside';
import { useContext, useState } from 'react';
import { CHANGE_PASSWORD, fetchData } from '../../components/Service';
import { displayError } from '../../components/Helper';
import { Context } from '../../components/Context';

const ChangePassword = () => {
    const [context] = useContext(Context)
    const [tinyLoader, setTinyLoader] = useState(false)
    const [data, setData] = useState(false)

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('change-password'));
        
        let data = {
            id: context && context.id,
            oldPassword:  formData.get("oldPassword"),
            password:  formData.get("password"),
            confirmPassword:  formData.get("confirmPassword")
        }

        setTinyLoader(type)
        setData("")

        fetchData(CHANGE_PASSWORD, 'POST', data, true, false, (res) => {
            setTinyLoader(false);
            if (res.status !== "success") {
                setData(res)
                displayError(res.errors)
            } else {
                
            }
        });
    }

    return (
        <div className="dashboard-content-block">
            <div className="dashbaord-heading">
                Change Password
            </div>
            <div className="admin-form-block">
                <Form id="change-password">
                    <Row>
                        <Col lg={7}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control type="password" name="oldPassword"/>
                                <span id="form-error-oldPassword" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" name="password"/>
                                <span id="form-error-password" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" name="confirmPassword"/>
                                <span id="form-error-confirmPassword" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="dash-buttons-block">
                        <Button variant="primary" onClick={(e) => saveData(e, 'update')} disabled={tinyLoader === "update"}>{ tinyLoader === "update" && <Spinner animation="grow" size="sm" /> } Change Password</Button>
                        <span className='error-message small m-5'>{data && data.message}</span>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ChangePassword;