import { Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { DSP_CREDENTIAL_DETAIL, DSP_CREDENTIAL_UPDATE, fetchData } from '../../components/Service';
import { displayError } from '../../components/Helper';

const DSPCredentials = () => {
    const [tinyLoader, setTinyLoader] = useState(false)

    const [dspData, setDspData] = useState()

    const saveData = (e, type) => {
        let formData = new FormData(document.getElementById('update-dsp'));

        setTinyLoader(type)

        let data = {
            msUserName: formData.get("msUserName"),
            msPassword: formData.get("msPassword"),
            epUserName: formData.get("epUserName"),
            epPassword: formData.get("epPassword"),
            sdUserName: formData.get("sdUserName"),
            sdPassword: formData.get("sdPassword"),
            saUserName: formData.get("saUserName"),
            saPassword: formData.get("saPassword")
        }

        fetchData(DSP_CREDENTIAL_UPDATE, 'POST', data, true, false, (res) => {
            setTinyLoader(false);
            if (res.status !== "success") {
                displayError(res.errors)
            }
        });
    }

    const getDSPDetails = () => {
        fetchData(DSP_CREDENTIAL_DETAIL, 'GET', "", true, false, (res) => {
            if (res.status === "success") {
                setDspData(res.records)
            }
        });
    }

    useEffect(() => {
        getDSPDetails()
    }, [])

    return (
        <div className="dashboard-content-block">
            <div className="dashbaord-heading">
                DSP
            </div>
            <div className="admin-form-block">
                <Form id="update-dsp">
                    <Row>
                        <Col lg={4}>
                            <h4 className="dsp-heading-name">Mediasmart</h4>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" name="msUserName" defaultValue={dspData && dspData.mediasmart && dspData.mediasmart.userName}/>
                                <span id="form-error-userName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" name="msPassword" defaultValue={dspData && dspData.mediasmart && dspData.mediasmart.password}/>
                                <span id="form-error-password" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <h4 className="dsp-heading-name">Epom</h4>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" name="epUserName" defaultValue={dspData && dspData.epom && dspData.epom.userName}/>
                                <span id="form-error-userName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" name="epPassword" defaultValue={dspData && dspData.epom && dspData.epom.password}/>
                                <span id="form-error-password" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <h4 className="dsp-heading-name">Smadex</h4>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" name="sdUserName" defaultValue={dspData && dspData.smadex && dspData.smadex.userName}/>
                                <span id="form-error-userName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" name="sdPassword" defaultValue={dspData && dspData.smadex && dspData.smadex.password}/>
                                <span id="form-error-password" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <h4 className="dsp-heading-name">SmartyAds</h4>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" name="saUserName" defaultValue={dspData && dspData.smartyads && dspData.smartyads.userName}/>
                                <span id="form-error-userName" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" name="saPassword" defaultValue={dspData && dspData.smartyads && dspData.smartyads.password}/>
                                <span id="form-error-password" className='form-input-error' ></span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="dash-buttons-block">
                        <Button variant="primary" onClick={(e) => saveData(e, 'update')} disabled={tinyLoader === "update"}>{ tinyLoader === "update" && <Spinner animation="grow" size="sm" /> } Update</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
};

export default DSPCredentials;