import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from "../../../components/Context";
import { CAMPAIGN_DISTRIBUTION, fetchData } from "../../../components/Service";
import { ActionButton1 } from "../../../components/Helper";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const Distribution = (props) => {
    const [Modal1, setModal1] = useState(false);
    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);

    const [Modal2, setModal2] = useState(false);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);

    const [dspErrors, setDSPErrors] = useState({
        msErr: "", 
        epErr: "", 
        saErr: "", 
        sdErr: ""
    });

    const navigate = useNavigate()
    const [checkedStatus, setCheckedStatus] = useState({
        meadiasmart: true,
        smadex: false,
        epom: false,
        smartyads: false,
        harmony: false,
        override: false
    });

    // Handler for checkbox change
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedStatus((prev) => ({
        ...prev,
        [name]: checked
        }));
    };

    const [context] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);

    const saveData = (e) => {
        let formData = new FormData(
            document.getElementById("campaign-distribution")
        );

        if(props && (props.campaignData === undefined || props.campaignData === "")) {
            handleShow2();
            return false;
        }

        if(props && props.campaignData) {
            if(!props.campaignData.name || !props.campaignData.startDate || !props.campaignData.endDate || 
                !props.campaignData.representativeCategory || !props.campaignData.domain || !props.campaignData.landingPageUrl ||
                !props.campaignData.limitBudget || !props.campaignData.geoTargetCountry || (!props.campaignData.bannerImage && 
                !props.campaignData.bannerVideo && !props.campaignData.bannerNativeImage)) {
                handleShow2();
                return false;
            }
        }

        let data = {
            userId: context && context.id ? context.id : 0,
            campaignId: context && context.campaignId ? context.campaignId : "",
            distType: JSON.stringify(checkedStatus),
            distComment: formData.get("distComment")
        };

        setTinyloader(true);

        fetchData(CAMPAIGN_DISTRIBUTION, "POST", data, true, false, (res) => {
            setTinyloader(false);
            if (res.status === "success") {
                handleShow1()
            } else {
                setDSPErrors(res.errors)
                handleShow2()
            }
        });
    };

    useEffect(() => {
        if(props && props.campaignData && props.campaignData.distType) {
            setCheckedStatus(JSON.parse(props.campaignData.distType));
        }
    }, [props])

    return (
        <>
            <div className="tab-inner-content">
                <Form  id="campaign-distribution">
                    <h4 className="form-heading">Distribution</h4>
                    <p>Select one or multiple DSP's for distribution. If none selected the system will automatically select one or several DSP's that best fit the campaign configuration.</p>
                    <div className="distribution-block">
                        <label htmlFor="meadiasmart" className={`dspcheck-block ${checkedStatus.meadiasmart ? 'selected' : ''}`}>
                        <input 
                            type="checkbox" 
                            id="meadiasmart" 
                            name="meadiasmart"
                            checked={checkedStatus.meadiasmart}
                            onChange={handleCheckboxChange}
                        />
                        <img src={'../images/mediasmart-logo.png'} alt="" />
                        </label>
                        <label htmlFor="smadex" className={`dspcheck-block ${checkedStatus.smadex ? 'selected' : ''}`}>
                        <input 
                            type="checkbox" 
                            id="smadex" 
                            name="smadex" 
                            checked={checkedStatus.smadex}
                            onChange={handleCheckboxChange}
                        />
                        <img src={'../images/smadex-logo.png'} alt="" />
                        </label>
                        <label htmlFor="epom" className={`dspcheck-block ${checkedStatus.epom ? 'selected' : ''}`}>
                        <input 
                            type="checkbox" 
                            id="epom" 
                            name="epom" 
                            checked={checkedStatus.epom}
                            onChange={handleCheckboxChange}
                        />
                        <img src={'../images/epom-logo.png'} alt="" />
                        </label>
                        <label htmlFor="smartyads" className={`dspcheck-block ${checkedStatus.smartyads ? 'selected' : ''}`}>
                        <input 
                            type="checkbox" 
                            id="smartyads" 
                            name="smartyads" 
                            checked={checkedStatus.smartyads}
                            onChange={handleCheckboxChange}
                        />
                        <img src={'../images/smartyads-logo.png'} alt="" />
                        </label>
                        <label htmlFor="harmony" className={`dspcheck-block ${checkedStatus.harmony ? 'selected' : ''}`}>
                        <input 
                            type="checkbox" 
                            id="harmony" 
                            name="harmony" 
                            checked={checkedStatus.harmony}
                            onChange={handleCheckboxChange}
                        />
                        <img src={'../images/harmony-ads-logo.png'} alt="" />
                        </label>
                    </div>
                    <div className="additional-input-block">
                        <Form.Group className="form-group">
                        <Form.Label>Additional Comments</Form.Label>
                        <Form.Control as="textarea" rows={3} name="distComment" defaultValue={props?.campaignData?.distComment} />
                        </Form.Group>
                    </div>
                    
                    <ActionButton1 saveData={saveData} tinyloader={tinyloader} />
                </Form>
            </div>
            <Modal size="sm" show={Modal1} onHide={handleClose1} centered className="custom-model-block">
                <Modal.Body>
                    <div className="success-campaign-block">
                        <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                        <h3>Campaign Submitted</h3>
                        <p>Your campaign has been successfully submitted.</p>
                        <Button variant="primary" className="btn-medium" onClick={() => navigate("/campaign/submited")}>New Campaign</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="md" show={Modal2} onHide={handleClose2} centered className="custom-model-block missing-data-block">
                <Modal.Body>
                <div className="close-icon-block" onClick={handleClose2}><FontAwesomeIcon icon="fa-solid fa-xmark" /></div>
                    <div className="success-campaign-block">
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
                        <h3>Missing Data</h3>
                        {props && props.campaignData && (!props.campaignData.name || !props.campaignData.startDate || !props.campaignData.endDate || 
                        !props.campaignData.representativeCategory || !props.campaignData.domain || !props.campaignData.landingPageUrl) && <p>Main tab has missing mandatory data/input.</p>}

                        {props && props.campaignData && !props.campaignData.limitBudget && <p>Budget tab has missing mandatory data/input.</p>}

                        {props && props.campaignData && !props.campaignData.geoTargetCountry && <p>Geo tab has missing mandatory data/input.</p>}

                        {props && props.campaignData && (!props.campaignData.bannerImage && !props.campaignData.bannerVideo && !props.campaignData.bannerNativeImage) && <p>Creatives tab has missing mandatory data/input.</p>}
                        
                        {props && (props.campaignData === undefined || props.campaignData === "") && 
                            <>
                                <p>Main tab has missing mandatory data/input.</p>
                                <p>Budget tab has missing mandatory data/input.</p>
                                <p>Geo tab has missing mandatory data/input.</p>
                                <p>Creatives tab has missing mandatory data/input.</p>
                            </>
                        }

                        {dspErrors && dspErrors.msErr && <p><strong>MediaSmart Error:</strong> {dspErrors.msErr}</p>}

                        {dspErrors && dspErrors.epErr && <p><strong>Epom Error:</strong> {dspErrors.epErr}</p>}
                        
                        {dspErrors && dspErrors.sdErr && <p><strong>Smadex Error:</strong> {dspErrors.sdErr}</p>}

                        {dspErrors && dspErrors.saErr && Object.entries(dspErrors.saErr).map(([key, value]) => (
                            <p key={key}><strong>SmartyAds Error:</strong> {value[0]}</p>
                        ))}
                        
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Distribution;
